<template>
  <b-modal
    id="bv-download-members-start-modal"
    title="Скачать участников старта"
    no-close-on-backdrop
    :ok-disabled="isloading"
  >
    <b-form-group
      label="Почта"
      class="mb-2"
    >
      <b-form-input
        v-model="email"
        class="links__input"
        placeholder="Email"
      />
    </b-form-group>
    <b-form-group label="По оплате">
      <v-select
        v-model="isPayed"
        taggable
        push-tags
        :options="isPayedOptions"
        label="name"
        placeholder="Выберите тип оплаты"
      />
    </b-form-group>
    <b-form-group label="По дистанции">
      <v-select
        v-model="selectedDistances"
        taggable
        push-tags
        multiple
        :options="distances"
        label="name"
        placeholder="Выберите дистанции"
      />
    </b-form-group>
    <template #modal-footer>
      <div class="buttons-wrapper">
        <b-button variant="secondary" block @click="hideModal">Отмена</b-button>
        <b-button :disabled="isloading" class="ml-1" variant="primary" block @click="downloadResults(true)">Скачать
        </b-button>
        <b-button :disabled="isloading" class="ml-1" variant="primary" block @click="downloadResults()">Отправить на
          почту
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import crudModule from '../../libs/crud_module'

const resultsModule = crudModule({
  entity_name: 'start/mail/send',
})

export default {
  name: 'DownloadStartMembersModal',
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    ToastificationContent,
  },
  props: {
    distances: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      email: null,
      isPayed: null,
      selectedDistances: [],
      isPayedOptions: [
        {
          name: 'Оплачено (включая бесплатные)',
          value: 0,
        },
        {
          name: 'Не оплачено',
          value: 1,
        },
      ],
      isloading: false,
    }
  },
  created() {
    if (localStorage.getItem('userData')) {
      this.email = JSON.parse(localStorage.getItem('userData')).email
    }
  },
  methods: {
    hideModal() {
      this.isPayed = null
      this.selectedDistances = []
      this.$bvModal.hide('bv-download-members-start-modal')
    },
    async downloadResults(download = false) {
      this.isloading = true
      try {
        const query = {}

        if (!this.email && !download) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка',
              icon: 'BellIcon',
              text: 'Необходимо ввести почту',
              variant: 'danger',
            },
          })
          this.isloading = false
          return
        }

        query.email = this.email
        query.start_id = this.$route.params.id

        if (download) {
          query.download = '1'
        }

        if (this.isPayed !== null) query.isPayed = this.isPayed.value
        if (this.selectedDistances.length !== 0) query.distances = this.selectedDistances.map(distance => distance.id)

        const r = await resultsModule
          .fetchItems({ ...query }, download ? {
            responseType: 'blob',
          } : {})

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Успешно',
            icon: 'BellIcon',
            text: download ? 'Скачиваем файл' : `${r.data.message} ${r.data.email}`,
            variant: 'success',
          },
        })
        if (download) {
          const url = window.URL.createObjectURL(new Blob([r.data]))
          const link = document.createElement('a')
          link.href = url
          link.download = 'report.xlsx'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
        }

      } catch (error) {
        this.isloading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка',
            icon: 'BellIcon',
            text: error?.response?.data?.message,
            variant: 'danger',
          },
        })
      } finally {
        this.isloading = false
        this.hideModal()
      }
    },
  },
}
</script>
<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.buttons-wrapper {
  display: flex;

  button {
    margin-top: 0
  }
}

.vs__actions {
  svg {
    fill: #7367f0;
  }
}
</style>
