<template>
  <b-modal
    id="modal-discount"
    ref="discount-modal"
    title="Создание/Редактирование шаблона персональных скидок"
    ok-only
    ok-title="Сохранить"
    @ok="updateTemplate"
  >
    <b-form-group
      label="Название шаблона"
      class="mt-1 mb-0"
    >
      <b-form-input
        v-model="template.name"
        placeholder="Название шаблона"
        required
        class="inline-block"
      />
    </b-form-group>
    <div
      v-for="(discount, i) in template.discounts"
      :key="'template.discounts_' + i"
      class="mt-2"
    >
      <div>
        <span class="discount-item">Скидка {{ i + 1 }}</span>
        <b-button
          title="Удалить"
          variant="flat-danger"
          class="btn-icon inline-block"
          size="sm"
          @click="template.discounts.splice(i, 1)"
        >
          <feather-icon icon="MinusIcon" />
        </b-button>
      </div>
      <b-form-group
        label="Пользователь"
        class="mt-1 mb-0"
      >
        <v-select
          v-model="discount.user"
          label="name"
          :filterable="false"
          :options="options"
          @search="onSearch"
        >
          <template slot="no-options">
            Введите Почту пользователя
          </template>
          <template
            slot="option"
            slot-scope="option"
          >
            <div class="d-center">
              {{ `${option.id} - ${option.name} ${option.surname}(${option.email})` }}
            </div>
          </template>
          <template
            slot="selected-option"
            slot-scope="option"
          >
            <div class="selected d-center">
              {{ `${option.name} ${option.surname}(${option.email})` }}
            </div>
          </template>
        </v-select>
      </b-form-group>
      <b-form-group
        label="Скидка"
        class="mt-1 mb-0"
      >
        <b-form-input
          v-model.number="discount.value"
          type="number"
          no-wheel
          placeholder="Размер скидки в %"
          class="inline-block"
        />
      </b-form-group>
    </div>
    <b-button
      class="mt-1"
      variant="primary"
      size="small"
      @click="template.discounts.push({user: null, value: null})"
    >Добавить скидку
    </b-button>
  </b-modal>
</template>

<script>
import {
  VBPopover, BButton, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import _ from 'lodash'
import crudModule from '@/libs/crud_module'

const discountModule = crudModule({
  entity_name: 'personal-discount-template',
})

const usersModule = crudModule({
  entity_name: 'user',
})

export default {
  name: 'DiscountTemplateModal',
  components: {
    BFormInput,
    BButton,
    BFormGroup,
    vSelect,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: {
    template: {
      type: Object,
    },
  },
  data() {
    return {
      options: [],
    }
  },
  methods: {
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.search(loading, search, this)
      }
    },
    search: _.debounce((loading, search, vm) => {
      usersModule.fetchItems({
        email: search,
      }).then(res => {
        vm.options = res.data.rows
        loading(false)
      })
    }, 350),
    updateTemplate(bvModalEvent) {
      if (!this.template.name) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Заполните поля',
            icon: 'BellIcon',
            text: 'Введите название шаблона',
            variant: 'danger',
          },
        })

        bvModalEvent.preventDefault()
        return
      }

      const discountsWithError = this.template.discounts.filter(discount => !discount.user || !discount.value)
      if (discountsWithError.length !== 0) {
        discountsWithError.forEach(discount => {
          if (!discount.user) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Заполните поля',
                icon: 'BellIcon',
                text: 'В персональной скидке необходимо выбрать пользователя',
                variant: 'danger',
              },
            })
          } else if (!discount.value) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Заполните поля',
                icon: 'BellIcon',
                text: 'В персональной скидке необходимо указать \'Размер скидки в %\' ',
                variant: 'danger',
              },
            })
          }
        })
        bvModalEvent.preventDefault()
        return
      }

      if (this.template.id) {
        this.saveTemplate()
      } else {
        this.createTemplate()
      }
    },
    async saveTemplate() {
      await discountModule.updateItemPatch(this.template.id, {
        name: this.template.name,
        instances: this.parseInstances(),
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Шаблон сохранен',
            variant: 'success',
          },
        })
        this.$refs['promo-modal'].hide()
        this.$emit('update')
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: error.response.data.message,
            variant: 'danger',
          },
        })
      })
    },
    async createTemplate() {
      await discountModule.createItem({
        name: this.template.name,
        instances: this.parseInstances(),
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Шаблон создан',
            variant: 'success',
          },
        })
        this.$refs['discount-modal'].hide()
        this.$emit('update')
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: error.response.data.message,
            variant: 'danger',
          },
        })
      })
    },
    parseInstances() {
      return this.template.discounts.map(item => ({
        user_id: item.user.id,
        value: item.value,
      }))
    },
  },
}
</script>
<style scoped lang="scss">
  .discount-item {
    font-size: 16px;
    font-weight: 500;
  }
</style>
