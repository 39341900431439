<script>
import {
  BFormCheckbox, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru'
import CheckpointBuilder from '@/views/starts/chip-sync/CheckpointBuilder.vue'

export default {
  name: 'OnlineTranslationOptions',
  components: {
    CheckpointBuilder,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    flatPickr,
  },
  data() {
    return {
      syncConfig: {
        start_date: new Date(),
        related_start_time: false,
        checkpointsSettings: {},
        sync_url: 'http://localhost:8080/test-data/3.csv',
        frequency: 30,
      },
      datePickerConfig: {
        allowInput: true,
        altFormat: 'd.m.Y H:i',
        altInput: true,
        enableTime: true,
        dateFormat: 'Z',
        locale: Russian,
      },
    }
  },
  mounted() {
    this.onConfigModified()
  },
  methods: {
    onConfigModified() {
      this.$emit('onConfigModified', this.syncConfig)
    },
    saveCheckpointsSettings(payload) {
      this.syncConfig.checkpointsSettings = payload
    },
  },
}
</script>

<template>
  <div>
    <h4 class="mt-3">
      Онлайн трансляция из файла
    </h4>

    <div>
      <b-form-group class="time-pick">
        <span>Начало старта</span>
        <flat-pickr
          v-model="syncConfig.start_date"
          class="form-control"
          :config="datePickerConfig"
          @input="onConfigModified"
        />
      </b-form-group>
    </div>

    <div class="mb-1">
      <span>Привязать результат к началу старта</span>

      <b-form-checkbox
        v-model="syncConfig.related_start_time"
        class="custom-control-primary"
        name="check-view"
        switch
        @change="onConfigModified"
      />
    </div>

    <div>
      <CheckpointBuilder
        class="mb-2"
        @onSettingsUpdated="saveCheckpointsSettings"
      />
      <vs-divider color="#404656" />
    </div>

    <div>
      <b-form-group
        label="Ссылка на файл для синхронизации"
        class="mb-2"
      >
        <b-form-input
          v-model="syncConfig.sync_url"
          class="links__input"
          placeholder="Ссылка на файл для синхронизации"
          @change="onConfigModified"
        />
      </b-form-group>
    </div>

    <div>
      <b-form-group
        label="Частота обновления (секунды)"
        class="mb-2"
      >
        <b-form-input
          v-model.number="syncConfig.frequency"
          type="number"
          no-wheel
          class="links__input"
          placeholder="Частота обновления (секунды)"
          @change="onConfigModified"
        />
      </b-form-group>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
