<template>
  <div>
    <div class="demo-inline-spacing" />
    <b-modal
      id="modal-primary"
      v-model="modalIsOpen"
      modal-class="modal-primary"
      centered
      :title="modalTitle"
    >
      <div>
        <p v-if="type === 'balance' || type === 'transaction'">
          Пользователь: {{ userData.name }} {{ userData.surname }}
        </p>
        <div v-if="type === 'transaction'">
          <div
            class="radio-buttons-container"
          >
            <b-form-radio
              v-model="transactionType"
              name="some-radios"
              :value="true"
            >
              Списание
            </b-form-radio>
            <b-form-radio
              v-model="transactionType"
              name="some-radios"
              :value="false"
            >
              Начисление
            </b-form-radio>
          </div>
          <div class="input-container">
            <div>
              <label for="input-live">Сумма транзакции *</label>
              <b-form-input
                id="input-live"
                v-model="transactionCount"
                aria-describedby="input-live-help input-live-feedback"
                placeholder="₽000.00"
                number
              />
            </div>
            <div>
              <label for="input-live">Комментарий *</label>
              <b-form-textarea
                id="textarea-state"
                v-model="transactionComment"
                :state="transactionComment.length < 255 && transactionComment.length > 0"
                placeholder="Комментарий к транзакции"
                rows="3"
              />
            </div>
          </div>
        </div>
        <div class="notification-container">
          <feather-icon
            icon="AlertCircleIcon"
            size="16"
            color="#FFA500"
          />
          <p>
            {{ notificationText }}
          </p>
        </div>
      </div>
      <template #modal-footer="">
        <b-button
          class="mt-1"
          variant="outline-primary"
          @click="$emit('close')"
        >
          Отмена
        </b-button>
        <b-button
          class="mt-1"
          variant="primary"
          :disabled="disableContinueButton"
          @click="createAction"
        >
          {{ type === 'transaction' ? 'Сохранить' : 'Продолжить' }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BFormRadio, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import crudModule from '../../libs/crud_module'
import ToastificationContent from '../../@core/components/toastification/ToastificationContent.vue'

const recalculateBalanceModule = crudModule({
  entity_name: 'virtual-payment/wallet/service-recalculate',
})

const createWalletsModule = crudModule({
  entity_name: 'virtual-payment/wallet/service-create',
})

const serviceTransactionsModule = crudModule({
  entity_name: 'virtual-payment/transaction/internal-credit',
})

export default {
  components: {
    BButton,
    BFormRadio,
    BFormInput,
    BFormTextarea,
  },
  props: {
    modalIsOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    userData: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      disableContinueButton: false,
      transactionType: true,
      transactionCount: null,
      transactionComment: '',
    }
  },
  computed: {
    modalTitle() {
      switch (this.type) {
        case 'balance':
          return 'Пересчитать баланс'
        case 'wallets':
          return 'Создать кошельки'
        case 'transaction':
          return 'Сервисная транзакция'
        default:
          return 'Пересчитать баланс'
      }
    },
    notificationText() {
      switch (this.type) {
        case 'balance':
          return 'Баланс кошелька будет пересчитан исходя из существующих успешно проведённых транзакций, для корректировки баланса - пополните баланс мануально.'
        case 'wallets':
          return 'Всем пользователям, у которых были ошибки при создании кошельков, будут созданы виртуальные кошельки. Любая транзакция для пользователя так же создаст кошелёк, если у него его не было.'
        case 'transaction':
          return 'Сервисная транзакция проводит транзакцию без физической оплаты от лица администратора и влияет на баланс кошелька.'
        default:
          return 'Баланс кошелька будет пересчитан исходя из существующих успешно проведённых транзакций, для корректировки баланса - пополните баланс мануально.'
      }
    },
  },
  methods: {
    async createAction() {
      this.disableContinueButton = true
      switch (this.type) {
        case 'balance':
          await this.recalculateBalance()
          break
        case 'wallets':
          await this.createWallets()
          break
        case 'transaction':
          await this.createServiceTransaction()
          break
        default:
          await this.recalculateBalance()
          break
      }
    },
    async recalculateBalance() {
      await recalculateBalanceModule.updateItemsPatch({ walletOwnerId: this.userData.id })
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'BellIcon',
              text: `Баланс кошелька пересчитан с ${res.data.previousBalance / 100} на ${res.data.recalculatedBalance / 100}`,
              variant: 'success',
            },
          })
          this.disableContinueButton = false
          this.$emit('recalculate', this.userData.id, res.data.recalculatedBalance)
          this.$emit('close')
        })
        .catch(err => {
          console.log(err)
        })
    },
    async createWallets() {
      await createWalletsModule.createItem()
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'BellIcon',
              text: `Успешно создались ${res.data.numberOfWalletsCreated} кошельков`,
              variant: 'success',
            },
          })
          this.disableContinueButton = false
          this.$emit('close')
        })
        .catch(err => {
          console.log(err)
        })
    },
    async createServiceTransaction() {
      if (!this.transactionCount || !this.transactionComment) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: 'Заполните все поля',
          },
        })
      } else {
        const payload = {
          transactionAmount: this.transactionCount * 100,
          isNegative: this.transactionType,
          walletOwnerId: this.userData.id,
          comment: this.transactionComment,
        }
        await serviceTransactionsModule.createItem(payload)
          .then(res => {
            console.log(res)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Успешно',
                icon: 'BellIcon',
                text: 'Сервисная транзакция успешно произведена!',
                variant: 'success',
              },
            })
            this.disableContinueButton = false
            this.$emit('close')
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.radio-buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.input-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
}
.notification-container {
    display: flex;
    align-items: center;
    gap: 10px;

    p {
        width: 95%;
        margin-bottom: 0;
    }
}

</style>
