import Vue from 'vue'

const ConfirmToDeleteService = new Vue({
  data() {
    return {
      isVisible: false,
      message: '',
      confirmCallback: null,
    }
  },
  methods: {
    /**
     * Open modal with confirm
     * @param {string} message - Confirm text
     * @param {function} callback - Func, called after confirm
     */
    open(message, callback) {
      this.message = message
      this.confirmCallback = callback
      this.isVisible = true
    },

    // Close modal
    close() {
      this.isVisible = false
      this.message = ''
      this.confirmCallback = null
    },

    // Perform confirmation
    confirm() {
      if (this.confirmCallback) {
        this.confirmCallback()
      }
      this.close()
    },
  },
})

export default ConfirmToDeleteService
