<template>
  <div>
    <b-badge
      :id="`badge_${index}_${memberStatus.id}`"
      :variant="memberStatus.type"
    >
      {{ memberStatus.name }}
      <feather-icon
        v-if="memberStatus.tooltip"
        icon="AlertCircleIcon"
      />
    </b-badge>
    <b-tooltip
      v-if="memberStatus.tooltip"
      :target="`badge_${index}_${memberStatus.id}`"
      :title="memberStatus.tooltip"
    />
  </div>
</template>

<script>
import {
  BBadge,
  BTooltip,
} from 'bootstrap-vue'

export default {
  name: 'MemberStatus',
  components: {
    BBadge,
    BTooltip,
  },
  props: ['status', 'index'],
  computed: {
    memberStatus() {
      switch (this.status) {
        case 0:
          return { id: 0, name: 'Не оплачено', type: 'secondary' }
        case 1:
          return { id: 1, name: 'Оплачено', type: 'success' }
        case 2:
          return {
            id: 2,
            name: 'Другой вид оплаты',
            type: 'secondary',
            tooltip: 'Полная оплата за старт проводится не в системе',
          }
        case 3:
          return { id: 3, name: 'Закончились слоты', type: 'danger' }
        case 4:
          return {
            id: 4,
            name: 'Бесплатно по скидке',
            type: 'warning',
            tooltip: 'Регистрация бесплатная из-за скидки',
          }
        case 5:
          return {
            id: 5,
            name: 'Доп поля оплачены',
            type: 'secondary',
            tooltip: 'Дополнительные поля оплачены, оплата за старт - вне системы',
          }
        case 6:
          return {
            id: 6,
            name: 'Доп поля не оплачены',
            type: 'secondary',
            tooltip: 'Дополнительные поля не оплачены, оплата за страт - вне системы',
          }
        default:
          return { id: 0, name: 'Не оплачено', type: 'secondary' }
      }
    },
  },
}
</script>
