<template>
  <div class="template-group">
    <b-modal
      id="modal-1"
      ref="group-modal"
      title="Создание/Редактирование шаблона групп"
      ok-only
      ok-title="Сохранить"
      @ok="updateTemplate"
    >
      <span>Имя шаблона</span>
      <b-form-input
        v-model="template.name"
        placeholder="Имя шаблона"
        required
        class="inline-block"
      />
      <div
        v-for="(group, i) in template.groups"
        :key="'template.groups_' + i"
        class="mt-2"
      >
        <div>
          <span class="group-item">Группа {{ i + 1 }}</span>
          <b-button
            title="Удалить"
            variant="flat-danger"
            class="btn-icon inline-block"
            size="sm"
            @click="template.groups.splice(i, 1)"
          >
            <feather-icon icon="MinusIcon" />
          </b-button>
        </div>
        <b-form-group
          label="Название"
          class="mt-1 mb-0"
        >
          <b-form-input
            v-model="group.name"
            placeholder="Название"
            class="inline-block"
          />
        </b-form-group>
        <b-form-group
          label="Возраст"
          class="mt-1 mb-0"
        >
          <b-row>
            <b-col class="md-6">
              <b-form-input
                id="group-age-from"
                v-model.number="group.age_from"
                type="number"
                no-wheel
                placeholder="От"
                class="inline-block"
              />
            </b-col>
            <b-col class="md-6">
              <b-form-input
                id="group-age-to"
                v-model.number="group.age_to"
                type="number"
                no-wheel
                placeholder="До"
                class="inline-block"
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label="Дата подсчета возраста"
          class="time-pick mt-1"
        >
          <flat-pickr
            v-model="group.age_reference_date"
            class="form-control"
            placeholder="Дата подсчета возраста"
            :config="config"
          />
        </b-form-group>
        <b-form-group label="Формат группы">
          <v-select
            v-model="group.format"
            taggable
            push-tags
            :options="formats"
            label="name"
            :reduce="option => option.value"
            @input="checkGroupFormat(i)"
          />
        </b-form-group>
        <div v-if="group.format === 'Minimum'">
          <b-form-group
            label="Пол"
            class="checkbox-container"
          >
            <b-form-radio-group
              v-model="group.min_sex"
              :options="optionsRadio"
              :name="`radio-inline-${i}`"
            />
          </b-form-group>
          <b-form-group
            label="Минимальное количество"
            class="mt-1"
          >
            <b-form-input
              id="group-age-to"
              v-model.number="group.min_number"
              type="number"
              no-wheel
              placeholder="Минимальное кол-во человек"
              class="inline-block"
            />
          </b-form-group>
        </div>
      </div>
      <b-button
        class="mt-1"
        variant="primary"
        size="small"
        @click="template.groups.push({name: null, format: null})"
      >Добавить группу
      </b-button>
    </b-modal>
    <b-button
      v-b-modal.modal-1
      variant="primary"
      class="mr-1"
      @click="template = {
        name: '',
        groups: []
      }"
    >
      Добавить шаблон
    </b-button>
    <div class="wrapper">
      <div
        v-for="(group, i) in groups"
        :key="'all-groups_' + i"
        style="margin-top: 20px; margin-bottom: 0!important;"
        class="card"
      >
        <div class="card-header">
          <div>
            <h4 class="card-title">
              {{ group.name }}
            </h4>
          </div>
        </div>
        <div class="card-body">
          <b-button
            v-b-modal.modal-1
            variant="primary"
            class="mr-1"
            @click="getTemplate(group.id)"
          >
            Редактировать шаблон
          </b-button>
          <b-button
            variant="primary"
            class="mr-1"
            @click="deleteTemplate(group.id)"
          >
            Удалить шаблон
          </b-button>
        </div>
      </div>
    </div>
    <infinite-loading
      spinner="bubbles"
      :identifier="infiniteTrigger"
      @infinite="infiniteScroll"
    />
  </div>
</template>

<script>
import {
  VBPopover, BButton, BFormInput, BFormGroup, BRow, BCol, BFormRadioGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
import InfiniteLoading from 'vue-infinite-loading'
import crud_module from '@/libs/crud_module'

const group_module = crud_module({
  entity_name: 'group-template',
})

export default {
  name: 'GroupPage',
  components: {
    BFormInput,
    BButton,
    BFormGroup,
    BFormRadioGroup,
    BRow,
    BCol,
    flatPickr,
    vSelect,
    InfiniteLoading,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      template: {
        name: '',
        groups: [],
      },
      groups: [],
      config: {
        allowInput: true,
        altFormat: 'd.m.Y H:i',
        altInput: true,
        enableTime: true,
        dateFormat: 'Z',
        locale: Russian, // locale for this instance only
      },
      formats: [
        {
          name: 'Мужской',
          value: 'Male',
        },
        {
          name: 'Женский',
          value: 'Female',
        },
        {
          name: 'Другой',
          value: 'Any',
        },
        {
          name: 'Минимум',
          value: 'Minimum',
        },
      ],
      optionsRadio: [
        { text: 'М', value: 'Male' },
        { text: 'Ж', value: 'Female' },
      ],
      skipCountGroupTemplates: 0,
      maxResultCountGroupTemplates: 10,
      infiniteTrigger: 0,
    }
  },
  methods: {
    async deleteTemplate(id) {
      await group_module.deleteItem(id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Уведомление',
              icon: 'BellIcon',
              text: 'Шаблон удален',
              variant: 'success',
            },
          })
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Уведомление',
              icon: 'BellIcon',
              text: error.response.data.message,
              variant: 'success',
            },
          })
        }).finally(() => {
          this.getTemplates()
        })
    },
    checkGroupFormat(index) {
      if (this.template.groups[index].format === 'Minimum') {
        this.template.groups[index].min_sex = 'Male'
      }
    },
    updateTemplate(bvModalEvent) {
      if (!this.template.name) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Заполните поля',
            icon: 'BellIcon',
            text: 'Введите название шаблона',
            variant: 'danger',
          },
        })

        bvModalEvent.preventDefault()
        return
      }

      const groupsWithError = this.template.groups.filter(group => !group.name || !group.format || (group.format === 'Minimum' && !group.min_number))
      if (groupsWithError.length !== 0) {
        groupsWithError.forEach(group => {
          if (!group.format) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Заполните поля',
                icon: 'BellIcon',
                text: `В группе ${group.name} необходимо выбрать обязательное поле 'Формат'`,
                variant: 'danger',
              },
            })
          } else if (!group.name) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Заполните поля',
                icon: 'BellIcon',
                text: 'В группе необходимо указать название',
                variant: 'danger',
              },
            })
          } else if (group.format === 'Minimum' && !group.min_number) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Заполните поля',
                icon: 'BellIcon',
                text: `В группе ${group.name} с форматом Минимум необходимо заполнить обязательное поле 'Минимальное кол-во участников' `,
                variant: 'danger',
              },
            })
          }
        })
        bvModalEvent.preventDefault()
        return
      }

      if (this.template.id) {
        this.saveTemplate()
      } else {
        this.createTemplate()
      }
    },
    async saveTemplate() {
      await group_module.updateItem(this.template.id, {
        name: this.template.name,
        instances: this.template.groups,
      }).then(() => {
        this.$emit('save')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Шаблон сохранен',
            variant: 'success',
          },
        })
        this.$refs['group-modal'].hide()
        this.template = {
          name: '',
          groups: [],
        }
        this.getTemplates()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: error.response.data.message,
            variant: 'danger',
          },
        })
      })
    },
    async createTemplate() {
      await group_module.createItem({
        name: this.template.name,
        instances: this.template.groups,
      }).then(() => {
        this.$emit('save')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Шаблон создан',
            variant: 'success',
          },
        })
        this.$refs['group-modal'].hide()
        this.template = {
          name: '',
          groups: [],
        }
        this.getTemplates()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: error.response.data.message,
            variant: 'danger',
          },
        })
      })
    },
    async getTemplate(id) {
      const template = await group_module.fetchItem(id).then(r => r.data)
      this.template.id = template.id
      this.template.name = template.name
      this.template.groups = template.instances
    },
    async getTemplates() {
      this.groups = []
      this.skipCountGroupTemplates = 0
      this.infiniteTrigger += 1
    },
    async infiniteScroll($state) {
      await group_module.fetchItems({
        skipCount: this.skipCountGroupTemplates,
        maxResultCount: this.maxResultCountGroupTemplates,
      }).then(r => {
        if (r.data.rows.length > 0) {
          this.groups.push(...r.data.rows)
          this.skipCountGroupTemplates += this.maxResultCountGroupTemplates
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.wrapper{
  display: flex;
  margin: 0 -15px;
  flex-wrap: wrap;
  .card{
    width: calc(33.33% - 30px);
    margin: 15px;
    @media (max-width: 1500px){
    width: calc(50% - 30px);
    }
    @media (max-width: 767px){
    width: 100%;
    }
  }
  .card-body{
    display: flex;
  }
}
.group-item {
  font-size: 16px;
  font-weight: 500;
}

.checkbox-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.time-pick {
  position: relative;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.vs__open-indicator {
  fill: #7367f0;
}
</style>
