<template>
  <b-form-input
    :value="formattedValue"
    type="number"
    no-wheel
    v-bind="filteredAttrs"
    class="inline-block"
    :placeholder="placeholder"
    :disabled="disabled"
    @input="updateValue"
    v-on="filteredListeners"
  />
</template>

<script>
import { BFormInput } from 'bootstrap-vue'

export default {
  name: 'PriceInputWrapper',
  components: { BFormInput },
  props: {
    value: Number,
    placeholder: {
      type: String,
      default: 'Цена',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedValue() {
      return (this.value / 100)
    },
    filteredAttrs() {
      const { value, ...attrs } = this.$attrs
      return attrs
    },
    filteredListeners() {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
  },
  methods: {
    updateValue(value) {
      const rubles = parseFloat(value)
      const kopecks = Math.round(rubles * 100)
      if (Number.isNaN(kopecks)) {
        this.$emit('input', 0)
        return
      }
      this.$emit('input', kopecks)
    },
  },
}
</script>
