import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import {
  getHomeRouteForLoggedInUser,
  getUserData,
  isUserLoggedIn,
} from '@/auth/utils'
import pages from '@/router/routes/pages'
import others from '@/router/routes/others'
import useJwt from '@/auth/jwt/useJwt'
import store from '../store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  scrollBehavior(to, from) {
    if (to.path === from.path) {
      return false
    }
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    ...pages,
    ...others,
    {
      path: '/shop',
      name: 'shop',
      component: () => import('@/views/shop/ShopPage.vue'),
      meta: {
        pageTitle: 'Магазин',
        breadcrumb: [
          {
            text: 'Магазин',
            active: true,
          },
        ],
      },
      children: [
        {
          path: 'categories',
          name: 'shop-categories',
          component: () => import('@/views/shop/CategoriesPage.vue'),
          meta: {
            pageTitle: 'Управление категориями',
            breadcrumb: [
              {
                text: 'Категории',
                active: true,
              },
            ],
          },
        },
        {
          path: 'products',
          component: () => import('@/views/common/ThroughComponent.vue'),
          children: [
            {
              path: '/',
              name: 'shop-products',
              component: () => import('@/views/shop/ProductsPage.vue'),
              meta: {
                pageTitle: 'Товары',
                breadcrumb: [
                  {
                    text: 'Товары',
                    active: true,
                  },
                ],
              },
            },
            {
              path: ':id',
              name: 'product-info',
              component: () => import('@/views/shop/ProductInfo.vue'),
              meta: {
                pageTitle: 'Товары',
                breadcrumb: [
                  {
                    text: 'Товары',
                    active: true,
                  },
                ],
                resource: 'ACL',
                action: 'manage',
              },
            },
          ],
        },
        {
          path: 'product-options',
          component: () => import('@/views/common/ThroughComponent.vue'),
          children: [
            {
              path: '/',
              name: 'product-options',
              component: () => import('@/views/shop/product-options/ProductOptionsPage.vue'),
              meta: {
                pageTitle: 'Опции товаров',
                breadcrumb: [
                  {
                    text: 'Опции товаров',
                    active: true,
                  },
                ],
              },
            },
            {
              path: ':id',
              name: 'product-option',
              component: () => import('@/views/shop/product-options/ProductOptionPage.vue'),
              meta: {
                pageTitle: 'Опция товаров',
                breadcrumb: [
                  {
                    text: 'Опция товаров',
                    active: true,
                  },
                ],
              },
            },
          ],
        },
        {
          path: 'size-description',
          name: 'size-description',
          component: () => import('@/views/shop/product-options/SizeDescriptionPage.vue'),
          meta: {
            pageTitle: 'Описания размеров',
            breadcrumb: [
              {
                text: 'Описания размеров',
                active: true,
              },
            ],
          },
        },
        {
          path: 'orders',
          name: 'shop-orders',
          component: () => import('@/views/common/ThroughComponent.vue'),
          children: [
            {
              path: '/',
              name: 'shop-orders',
              component: () => import('@/views/shop/orders/OrdersPage.vue'),
              meta: {
                pageTitle: 'Заказы',
                breadcrumb: [
                  {
                    text: 'Заказы',
                    active: true,
                  },
                ],
              },
            },
            {
              path: ':id',
              name: 'shop-order',
              component: () => import('@/views/shop/orders/OrderPage.vue'),
              meta: {
                pageTitle: 'Заказ',
                breadcrumb: [
                  {
                    text: 'Заказ',
                    active: true,
                  },
                ],
              },
            },
          ],
        },
        {
          path: 'upload',
          name: 'shop-upload',
          component: () => import('@/views/shop/orders/OrdersPage.vue'),
          meta: {
            pageTitle: 'Загрузка товаров',
            breadcrumb: [
              {
                text: 'Товары',
                active: true,
              },
            ],
          },
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/views/common/ThroughComponent.vue'),
      children: [
        {
          path: '/',
          name: 'starts',
          component: () => import('@/views/starts/StartsPage.vue'),
          meta: {
            pageTitle: 'Старты',
            breadcrumb: [
              {
                text: 'Старты',
                active: true,
              },
            ],
          },
        },
        {
          path: 'calendar',
          name: 'starts-calendar',
          component: () => import('@/views/starts/Calendar.vue'),
          meta: {
            pageTitle: 'Календарь стартов',
            breadcrumb: [
              {
                text: 'Старты',
                active: true,
              },
            ],
          },
        },
        {
          path: 'templates',
          component: () => import('@/views/common/ThroughComponent.vue'),
          children: [
            {
              path: 'groups',
              name: 'templates-groups',
              component: () => import('@/views/starts/GroupPage.vue'),
              meta: {
                pageTitle: 'Шаблоны возрастных групп',
                breadcrumb: [
                  {
                    text: 'Старты',
                    active: true,
                  },
                ],
              },
            },
            {
              path: 'promocodes',
              name: 'templates-promos',
              component: () => import('@/views/starts/templates/promocodes/PromocodesPage.vue'),
              meta: {
                pageTitle: 'Шаблоны промокодов',
                breadcrumb: [
                  {
                    text: 'Старты',
                    active: true,
                  },
                ],
              },
            },
            {
              path: 'discounts',
              name: 'templates-discounts',
              component: () => import('@/views/starts/templates/discounts/DiscountsPage.vue'),
              meta: {
                pageTitle: 'Шаблоны персональных скидок',
                breadcrumb: [
                  {
                    text: 'Старты',
                    active: true,
                  },
                ],
              },
            },
          ],
        },
        {
          path: 'cities',
          name: 'starts-cities',
          component: () => import('@/views/starts/EditCities.vue'),
          meta: {
            pageTitle: 'Города',
            breadcrumb: [
              {
                text: 'Старты',
                active: true,
              },
            ],
          },
        },
        {
          path: 'teams',
          name: 'teams',
          component: () => import('@/views/starts/TeamsList.vue'),
          meta: {
            pageTitle: 'Список команд',
            breadcrumb: [
              {
                text: 'Старты',
                active: true,
              },
            ],
          },
        },
        {
          path: 'albums',
          component: () => import('@/views/common/ThroughComponent.vue'),
          children: [
            {
              path: '/',
              name: 'albums',
              component: () => import('@/views/starts/album/AlbumsPage.vue'),
              meta: {
                pageTitle: 'Альбомы',
                breadcrumb: [
                  {
                    text: 'Альбомы',
                    active: true,
                  },
                ],
              },
            },
            {
              path: ':id',
              name: 'photo',
              component: () => import('@/views/starts/album/AlbumPage.vue'),
              meta: {
                pageTitle: 'Альбом',
                breadcrumb: [
                  {
                    text: 'Альбом',
                    active: true,
                  },
                ],
              },
            },
          ],
        },
        {
          path: 'seasons',
          component: () => import('@/views/common/ThroughComponent.vue'),
          children: [
            {
              path: '/',
              name: 'seasons',
              component: () => import('@/views/starts/seasons/SeasonsPage.vue'),
              meta: {
                pageTitle: 'Сезоны',
                breadcrumb: [
                  {
                    text: 'Сезоны',
                    active: true,
                  },
                ],
              },
            },
            {
              path: ':id',
              name: 'season',
              component: () => import('@/views/starts/seasons/SeasonPage.vue'),
              meta: {
                pageTitle: 'Сезон',
                breadcrumb: [
                  {
                    text: 'Сезон',
                    active: true,
                  },
                ],
              },
            },
          ],
        },
        {
          path: 'donations',
          component: () => import('@/views/common/ThroughComponent.vue'),
          children: [
            {
              path: '/',
              name: 'donations',
              component: () => import('@/views/starts/donations/DonationsPage.vue'),
              meta: {
                pageTitle: 'Донаты',
                breadcrumb: [
                  {
                    text: 'Донаты',
                    active: true,
                  },
                ],
              },
            },
            {
              path: ':id',
              name: 'donation',
              component: () => import('@/views/starts/donations/DonationPage.vue'),
              meta: {
                pageTitle: 'Донат',
                breadcrumb: [
                  {
                    text: 'Донат',
                    active: true,
                  },
                ],
              },
            },
          ],
        },
        {
          path: ':id',
          name: 'start-page',
          component: () => import('@/views/starts/StartPage.vue'),
          meta: {
            pageTitle: 'Старты',
            breadcrumb: [
              {
                text: 'Старты',
                active: true,
              },
            ],
            resource: 'ACL',
            action: 'manage',
          },
        },
      ],
    },
    {
      path: '/storage',
      component: () => import('@/views/common/ThroughComponent.vue'),
      children: [
        {
          path: 'new',
          name: 'storage-new',
          component: () => import('@/views/storage/NewProducts.vue'),
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/news',
      component: () => import('@/views/common/ThroughComponent.vue'),
      children: [
        {
          path: '/',
          name: 'news',
          component: () => import('@/views/news/NewsPage.vue'),
          meta: {
            pageTitle: 'Новости',
            breadcrumb: [
              {
                text: 'Новости',
                active: true,
              },
            ],
          },
        },
        {
          path: ':id',
          name: 'one-news-page',
          component: () => import('@/views/news/OneNewsPage.vue'),
          meta: {
            pageTitle: 'Новость',
            breadcrumb: [
              {
                text: 'Новость',
                active: true,
              },
            ],
          },
        },
        {
          path: 'news-categories',
          component: () => import('@/views/common/ThroughComponent.vue'),
          children: [
            {
              path: '/',
              name: 'news-categories',
              component: () => import('@/views/news/categories/CategoriesPage.vue'),
              meta: {
                pageTitle: 'Категории',
                breadcrumb: [
                  {
                    text: 'Категории',
                    active: true,
                  },
                ],
              },
            },
            {
              path: ':id',
              name: 'news-category',
              component: () => import('@/views/news/categories/CategoryPage.vue'),
              meta: {
                pageTitle: 'Категория',
                breadcrumb: [
                  {
                    text: 'Категория',
                    active: true,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      path: '/clubs',
      component: () => import('@/views/common/ThroughComponent.vue'),
      children: [
        // {
        //   path: '/',
        //   name: 'clubs',
        //   component: () => import('@/views/clubs/ClubsPage.vue'),
        //   meta: {
        //     pageTitle: 'Клубы',
        //     breadcrumb: [
        //       {
        //         text: 'Клубы',
        //         active: true,
        //       },
        //     ],
        //   }
        // },
        {
          path: 'workouts',
          component: () => import('@/views/common/ThroughComponent.vue'),
          children: [
            {
              path: '/',
              name: 'workouts',
              component: () => import('@/views/clubs/workouts/WorkoutsPage.vue'),
              meta: {
                pageTitle: 'Тренировки',
                breadcrumb: [
                  {
                    text: 'Тренировки',
                    active: true,
                  },
                ],
              },
            },
            {
              path: ':id',
              name: 'workout-page',
              component: () => import('@/views/clubs/workouts/WorkoutPage.vue'),
              meta: {
                pageTitle: 'Тренировка',
                breadcrumb: [
                  {
                    text: 'Тренировка',
                    active: true,
                  },
                ],
              },
            },
          ],
        },
        {
          path: 'trainers',
          component: () => import('@/views/common/ThroughComponent.vue'),
          children: [
            {
              path: '/',
              name: 'trainers',
              component: () => import('@/views/clubs/trainers/TrainersPage.vue'),
              meta: {
                pageTitle: 'Тренеры',
                breadcrumb: [
                  {
                    text: 'Тренеры',
                    active: true,
                  },
                ],
              },
            },
            {
              path: ':id',
              name: 'trainer-page',
              component: () => import('@/views/clubs/trainers/TrainerPage.vue'),
              meta: {
                pageTitle: 'Тренер',
                breadcrumb: [
                  {
                    text: 'Тренер',
                    active: true,
                  },
                ],
              },
            },
          ],
        },
        {
          path: 'kindsofsports',
          component: () => import('@/views/common/ThroughComponent.vue'),
          children: [
            {
              path: '/',
              name: 'kindsofsports',
              component: () => import('@/views/clubs/kindsofsports/KindsOfSportsPage.vue'),
              meta: {
                pageTitle: 'Виды спорта',
                breadcrumb: [
                  {
                    text: 'Виды спорта',
                    active: true,
                  },
                ],
              },
            },
            {
              path: ':id',
              name: 'kindofsports-page',
              component: () => import('@/views/clubs/kindsofsports/KindOfSportsPage.vue'),
              meta: {
                pageTitle: 'Вид спорта',
                breadcrumb: [
                  {
                    text: 'Вид спорта',
                    active: true,
                  },
                ],
              },
            },
          ],
        },
        {
          path: 'schedules',
          component: () => import('@/views/common/ThroughComponent.vue'),
          children: [
            {
              path: '/',
              name: 'schedules',
              component: () => import('@/views/clubs/schedules/SchedulesPage.vue'),
              meta: {
                pageTitle: 'Расписания',
                breadcrumb: [
                  {
                    text: 'Расписания',
                    active: true,
                  },
                ],
              },
            },
            {
              path: ':id',
              name: 'schedule-page',
              component: () => import('@/views/clubs/schedules/SchedulePage.vue'),
              meta: {
                pageTitle: 'Расписание',
                breadcrumb: [
                  {
                    text: 'Расписание',
                    active: true,
                  },
                ],
              },
            },
          ],
        },
        {
          path: 'subscriptions',
          component: () => import('@/views/common/ThroughComponent.vue'),
          children: [
            {
              path: '/',
              name: 'subscriptions',
              component: () => import('@/views/clubs/subscriptions/SubscriptionsPage.vue'),
              meta: {
                pageTitle: 'Абонементы',
                breadcrumb: [
                  {
                    text: 'Абонементы',
                    active: true,
                  },
                ],
              },
            },
            {
              path: ':id',
              name: 'subscription-page',
              component: () => import('@/views/clubs/subscriptions/SubscriptionPage.vue'),
              meta: {
                pageTitle: 'Абонемент',
                breadcrumb: [
                  {
                    text: 'Абонемент',
                    active: true,
                  },
                ],
              },
            },
          ],
        },
        {
          path: 'requests',
          component: () => import('@/views/common/ThroughComponent.vue'),
          children: [
            {
              path: '/',
              name: 'requests',
              component: () => import('@/views/clubs/requests/RequestsPage.vue'),
              meta: {
                pageTitle: 'Запросы',
                breadcrumb: [
                  {
                    text: 'Запросы',
                    active: true,
                  },
                ],
              },
            },
            {
              path: ':id',
              name: 'request',
              component: () => import('@/views/clubs/requests/RequestPage.vue'),
              meta: {
                pageTitle: 'Запрос',
                breadcrumb: [
                  {
                    text: 'Запрос',
                    active: true,
                  },
                ],
              },
            },
          ],
        },
        {
          path: 'questions',
          component: () => import('@/views/common/ThroughComponent.vue'),
          children: [
            {
              path: '/',
              name: 'questions',
              component: () => import('@/views/clubs/questions/QuestionsPage.vue'),
              meta: {
                pageTitle: 'Вопросы',
                breadcrumb: [
                  {
                    text: 'Вопросы',
                    active: true,
                  },
                ],
              },
            },
            {
              path: ':id',
              name: 'question-page',
              component: () => import('@/views/clubs/questions/QuestionPage.vue'),
              meta: {
                pageTitle: 'Вопрос',
                breadcrumb: [
                  {
                    text: 'Вопрос',
                    active: true,
                  },
                ],
              },
            },
          ],
        },
        {
          path: 'questions-categories',
          component: () => import('@/views/common/ThroughComponent.vue'),
          children: [
            {
              path: '/',
              name: 'questions-categories',
              component: () => import('@/views/clubs/questions/QuestionsCategoriesPage.vue'),
              meta: {
                pageTitle: 'Категории',
                breadcrumb: [
                  {
                    text: 'Категории',
                    active: true,
                  },
                ],
              },
            },
            {
              path: ':id',
              name: 'questions-category',
              component: () => import('@/views/clubs/questions/QuestionsCategoryPage.vue'),
              meta: {
                pageTitle: 'Категория',
                breadcrumb: [
                  {
                    text: 'Категория',
                    active: true,
                  },
                ],
              },
            },
          ],
        },
        {
          path: 'clubs-settings',
          component: () => import('@/views/common/ThroughComponent.vue'),
          children: [
            {
              path: '/',
              name: 'clubs-settings',
              component: () => import('@/views/clubs/settings/SettingsPage.vue'),
              meta: {
                pageTitle: 'Настройки страницы',
                breadcrumb: [
                  {
                    text: 'Настройки страницы',
                    active: true,
                  },
                ],
              },
            },
            {
              path: ':id',
              name: 'block',
              component: () => import('@/views/clubs/settings/SettingPage.vue'),
              meta: {
                pageTitle: 'Блок',
                breadcrumb: [
                  {
                    text: 'Блок',
                    active: true,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      path: '/users',
      component: () => import('@/views/common/ThroughComponent.vue'),
      children: [
        {
          path: '/',
          name: 'users',
          component: () => import('@/views/users/UsersPage.vue'),
          meta: {
            pageTitle: 'Пользователи',
            breadcrumb: [
              {
                text: 'Пользователи',
                active: true,
              },
            ],
          },
        },
        {
          path: ':id',
          name: 'user-page',
          component: () => import('@/views/users/UserPage.vue'),
          meta: {
            pageTitle: 'Пользователь',
            breadcrumb: [
              {
                text: 'Пользователь',
                active: true,
              },
            ],
          },
        },
      ],
    },
    {
      path: '/transactions',
      component: () => import('@/views/common/ThroughComponent.vue'),
      children: [
        {
          path: '/',
          name: 'transactions',
          component: () => import('@/views/transactions/TransactionsPage.vue'),
          meta: {
            pageTitle: 'Транзакции',
            breadcrumb: [
              {
                text: 'Транзакции',
                active: true,
              },
            ],
          },
        },
        {
          path: 'statistics',
          name: 'statistics',
          component: () => import('@/views/transactions/StatisticsPage.vue'),
          meta: {
            pageTitle: 'Статистика',
            breadcrumb: [
              {
                text: 'Статистика',
                active: true,
              },
            ],
          },
        },
      ],
    },
    {
      path: '/virtual-payment',
      component: () => import('@/views/common/ThroughComponent.vue'),
      children: [
        {
          path: 'wallets',
          name: 'wallets',
          component: () => import('@/views/virtualPayment/WalletsPage.vue'),
          meta: {
            pageTitle: 'Виртуальные Кошельки',
            breadcrumb: [
              {
                text: 'Кошельки',
                active: true,
              },
            ],
          },
        },
        {
          path: 'virtual-transactions',
          name: 'virtual-transactions',
          component: () => import('@/views/virtualPayment/TransactionsPage.vue'),
          meta: {
            pageTitle: 'Виртуальные Транзакции',
            breadcrumb: [
              {
                text: 'Транзакции',
                active: true,
              },
            ],
          },
        },
      ],
    },
    {
      path: '/client',
      component: () => import('@/views/common/ThroughComponent.vue'),
      children: [
        {
          path: 'posts',
          name: 'posts',
          component: () => import('@/views/client/PostsPage.vue'),
          meta: {
            pageTitle: 'Публикации',
            breadcrumb: [
              {
                text: 'Публикации',
                active: true,
              },
            ],
          },
        },
        {
          path: ':id',
          name: 'post',
          component: () => import('@/views/client/PostPage.vue'),
          meta: {
            pageTitle: 'Пост',
            breadcrumb: [
              {
                text: 'Пост',
                active: true,
              },
            ],
          },
        },
        {
          path: 'external-registrations',
          name: 'external-registrations',
          component: () => import('@/views/client/ExternalRegistrationsPage.vue'),
          meta: {
            pageTitle: 'Внешние Регистрации',
            breadcrumb: [
              {
                text: 'Регистрации',
                active: true,
              },
            ],
          },
        },
      ],
    },
    {
      path: '/sync',
      component: () => import('@/views/common/ThroughComponent.vue'),
      children: [
        {
          path: '/',
          name: 'sync',
          component: () => import('@/views/sync/SyncPage.vue'),
          meta: {
            pageTitle: 'Синхронизация',
            breadcrumb: [
              {
                text: 'Синхронизация',
                active: true,
              },
            ],
          },
        },
      ],
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, _, next) => {
  if (to.name === 'auth-login') {
    return next()
  }

  useJwt.me()
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (
    to.meta.redirectIfLoggedIn
    && isLoggedIn
    && !store.state.loginModal.loginModalIsOpen
  ) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

export default router
