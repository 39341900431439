<template>
  <div v-if="currentMember">
    <div class="input">
      <span>
        Имя
      </span>
      <b-form-input
        v-model="currentMember.name"
        placeholder="Имя"
      />
    </div><div class="input">
      <span>
        Фамилия
      </span>
      <b-form-input
        v-model="currentMember.surname"
        placeholder="Фамилия"
      />
    </div>
    <div class="input">
      <span>
        Пол
      </span>
      <b-form-input
        v-model="currentMember.gender"
        placeholder="Пол"
      />
    </div><div class="input">
      <span>
        Email
      </span>
      <b-form-input
        v-model="currentMember.email"
        placeholder="Email"
      />
    </div>
    <div class="input">
      <span>
        Телефон
      </span>
      <b-form-input
        v-model="currentMember.phone"
        placeholder="Телефон"
      />
    </div>
    <div class="input">
      <span>
        Команда
      </span>
      <b-form-input
        v-model="currentMember.team"
        placeholder="Команда"
      />
    </div>
    <div class="input">
      <span>
        Город
      </span>
      <v-select
        v-model="currentMember.city"
        taggable
        push-tags
        placeholder="Город"
        :options="cities"
        label="name"
        :reduce="option => option.name"
      />
    </div>
    <div class="input">
      <span>
        Дата рождения
      </span>
      <flat-pickr
        v-model="currentMember.birthday"
        class="form-control"
        :config="config"
      />
    </div>
    <b-button
      variant="primary"
      class="mr-1"
      @click="saveMember"
    >
      Сохранить
    </b-button>
  </div>
</template>

<script>
import {
  BFormInput, BButton, VBPopover,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import crud_module from '@/libs/crud_module'

const member_module = crud_module({
  entity_name: 'member-start',
})

export default {
  name: 'EditMember',
  components: {
    BFormInput,
    flatPickr,
    BButton,
    vSelect,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: ['member', 'modalRef', 'cities'],
  data() {
    return {
      currentMember: null,
      config: {
        allowInput: true,
        altFormat: 'd.m.Y',
        altInput: true,
        enableTime: true,
        dateFormat: 'Z',
        locale: Russian, // locale for this instance only
      },
    }
  },
  async created() {
    this.currentMember = { ...this.member }
  },
  methods: {
    async saveMember() {
      await member_module.updateItem(this.currentMember.id, this.currentMember).then(r => {
        this.$emit('save')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Данные участника успешно изменены',
            variant: 'success',
          },
        })
        this.modalRef.hide()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: error.response.data.message,
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>

<style scoped lang="scss">
.input{
  margin-bottom: 15px;
  span{
    padding-bottom: 8px;
    display: block;
  }
}
</style>
