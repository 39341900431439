import { render, staticRenderFns } from "./PostPage.vue?vue&type=template&id=30c2f621&scoped=true&"
import script from "./PostPage.vue?vue&type=script&lang=js&"
export * from "./PostPage.vue?vue&type=script&lang=js&"
import style0 from "./PostPage.vue?vue&type=style&index=0&id=30c2f621&lang=scss&scoped=true&"
import style1 from "./PostPage.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30c2f621",
  null
  
)

export default component.exports