<template>
  <b-modal
    id="modal-promo"
    ref="promo-modal"
    title="Создание/Редактирование шаблона промокодов"
    ok-only
    ok-title="Сохранить"
    @ok="updateTemplate"
  >
    <b-form-group
      label="Название шаблона"
      class="mt-1 mb-0"
    >
      <b-form-input
        v-model="template.name"
        placeholder="Название шаблона"
        required
        class="inline-block"
      />
    </b-form-group>
    <div
      v-for="(promo, i) in template.promos"
      :key="'template.promos_' + i"
      class="mt-2"
    >
      <div>
        <span class="promo-item">Промокод {{ i + 1 }}</span>
        <b-button
          title="Удалить"
          variant="flat-danger"
          class="btn-icon inline-block"
          size="sm"
          @click="template.promos.splice(i, 1)"
        >
          <feather-icon icon="MinusIcon" />
        </b-button>
      </div>
      <b-form-group
        label="Промокод"
        class="mt-1 mb-0"
      >
        <b-form-input
          v-model="promo.code"
          placeholder="Промокод"
          class="inline-block"
        />
      </b-form-group>
      <b-form-group
        label="Скидка"
        class="mt-1 mb-0"
      >
        <b-form-input
          v-model.number="promo.value"
          type="number"
          no-wheel
          placeholder="Размер скидка в %"
          class="inline-block"
        />
      </b-form-group>
      <b-form-group
        label="Лимит использований"
        class="mt-1 mb-0"
      >
        <b-form-input
          v-model.number="promo.usageLimitInitial"
          type="number"
          no-wheel
          placeholder="Лимит использований"
          class="inline-block"
        />
      </b-form-group>
    </div>
    <b-button
      class="mt-1"
      variant="primary"
      size="small"
      @click="template.promos.push({code: null, value: null})"
    >Добавить промокод
    </b-button>
  </b-modal>
</template>

<script>
import {
  VBPopover, BButton, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import crudModule from '@/libs/crud_module'

const promoModule = crudModule({
  entity_name: 'promocode-template',
})

export default {
  name: 'PromoTemplateModal',
  components: {
    BFormInput,
    BButton,
    BFormGroup,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: {
    template: {
      type: Object,
    },
  },
  methods: {
    updateTemplate(bvModalEvent) {
      if (!this.template.name) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Заполните поля',
            icon: 'BellIcon',
            text: 'Введите название шаблона',
            variant: 'danger',
          },
        })

        bvModalEvent.preventDefault()
        return
      }

      const promosWithError = this.template.promos.filter(promo => !promo.code || !promo.value)
      if (promosWithError.length !== 0) {
        promosWithError.forEach(promo => {
          if (!promo.code) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Заполните поля',
                icon: 'BellIcon',
                text: 'В промокоде необходимо указать название',
                variant: 'danger',
              },
            })
          } else if (!promo.value) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Заполните поля',
                icon: 'BellIcon',
                text: `В промокоде ${promo.code} необходимо указать 'Размер скидки в %' `,
                variant: 'danger',
              },
            })
          }
        })
        bvModalEvent.preventDefault()
        return
      }

      if (this.template.id) {
        this.saveTemplate()
      } else {
        this.createTemplate()
      }
    },
    async saveTemplate() {
      await promoModule.updateItem(this.template.id, {
        name: this.template.name,
        instances: this.template.promos,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Шаблон сохранен',
            variant: 'success',
          },
        })
        this.$refs['promo-modal'].hide()
        this.$emit('update')
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: error.response.data.message,
            variant: 'danger',
          },
        })
      })
    },
    async createTemplate() {
      await promoModule.createItem({
        name: this.template.name,
        instances: this.template.promos,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Шаблон создан',
            variant: 'success',
          },
        })
        this.$refs['promo-modal'].hide()
        this.$emit('update')
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: error.response.data.message,
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>
<style scoped lang="scss">
.promo-item {
    font-size: 16px;
    font-weight: 500;
}
</style>
