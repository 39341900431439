<template>
  <div class="card">
    <div class="card-body">
      <div class="custom-search d-flex justify-content-between align-items-center">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1 search-title">Поиск</label>
            <b-form-input
              v-model="searchData"
              placeholder="Поиск"
              type="text"
              class="d-inline-block"
              @update="(search, loading) => searchWallets(search, loading)"
            />
          </div>
        </b-form-group>
        <div class="header-button-container">
          <div class="notification-container">
            <feather-icon
              icon="AlertCircleIcon"
              size="16"
            />
            <p>
              В случае ошибок при создании юзеров, несозданные виртуальные кошельки можно создать вручную
            </p>
          </div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            @click="openModal('wallets')"
          >
            Создать кошельки
          </b-button>
        </div>
      </div>

      <vue-good-table
        :columns="columns"
        :rows="rows"
        :sort-options="{
          enabled: false,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'user'">
            {{ props.row.name }} {{ props.row.surname }}
          </span>

          <span v-else-if="props.column.field === 'wallet'">
            {{ props.row.wallet ? props.row.wallet.balance / 100 : '-' }}
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-b-modal.modal-primary
                  variant="outline-primary"
                  @click.prevent="redirectToTransactions(props.row.name, props.row.surname)"
                >
                  <span>Перейти к транзакциям</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.modal-primary
                  variant="outline-primary"
                  @click.prevent="openModal('transaction', props.row)"
                >
                  <span>Сервисная транзакция</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.modal-primary
                  variant="outline-primary"
                  @click.prevent="openModal('balance', props.row)"
                >
                  <span>Пересчитать баланс</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
      <infinite-loading
        spinner="bubbles"
        @infinite="infiniteScroll"
      >
        <div slot="no-more" />
        <div slot="no-results" />
      </infinite-loading>
    </div>

    <WalletNotificationModal
      :modal-is-open="modalNotificationIsOpen"
      :user-data="selectedRowUserData"
      :type="modalNotificationType"
      @close="modalNotificationIsOpen = false"
      @recalculate="recalculateUserBalance"
    />
  </div>
</template>

<script>
import {
  BDropdown,
  BFormGroup,
  BFormInput,
  BDropdownItem,
  BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import InfiniteLoading from 'vue-infinite-loading'
import crudModule from '../../libs/crud_module'
import WalletNotificationModal from './WalletNotificationModal.vue'

const walletsModule = crudModule({
  entity_name: 'user/virtual-payment/wallets',
})

export default {
  components: {
    VueGoodTable,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BButton,
    InfiniteLoading,
    WalletNotificationModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      skipCount: 0,
      maxResultCount: 10,
      searchData: '',
      modalNotificationIsOpen: false,
      modalNotificationType: '',
      selectedRowUserData: {},
      scrollState: undefined,
      columns: [
        {
          label: 'Пользователь',
          field: 'user',
        },
        {
          label: 'Номер телефона',
          field: 'number',
        },
        {
          label: 'e-mail',
          field: 'email',
        },
        {
          label: 'Баланс кошелька',
          field: 'wallet',
        },
        {
          label: 'Действие',
          field: 'action',
        },
      ],
      rows: [],
    }
  },
  methods: {
    async infiniteScroll($state) {
      this.scrollState = $state
      const payload = {
        filterText: this.searchData,
        skipCount: this.skipCount,
        maxResultCount: this.maxResultCount,
      }
      await walletsModule.fetchItems(payload)
        .then(res => {
          if (res.data.rows.length > 0) {
            this.rows.push(...res.data.rows)
            this.skipCount += this.maxResultCount
            $state.loaded()
          } else {
            $state.complete()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    redirectToTransactions(name, surname) {
      this.$router.push({ name: 'virtual-transactions', query: { filterText: `${name} ${surname}` } })
    },
    openModal(type, data) {
      if (data) {
        this.selectedRowUserData = data
      }
      this.modalNotificationType = type
      this.modalNotificationIsOpen = true
    },
    recalculateUserBalance(userId, balance) {
      const user = this.rows.find(row => row.id === userId)
      if (user.wallet) {
        user.wallet.balance = balance
      }
    },
    debounceSearch(search, loading, fn) {
      clearTimeout(this.debounce)
      if (search.length >= 0) {
        this.debounce = setTimeout(fn, 600)
      }
    },
    searchWallets(search, loading) {
      this.debounceSearch(search, loading, async () => {
        try {
          await walletsModule.fetchItems({
            filterText: search,
            skipCount: 0,
            maxResultCount: this.maxResultCount,
          })
            .then(result => {
              this.rows = result.data.rows
              if (this.scrollState) {
                this.scrollState.reset()
              }
            })
        } catch (e) {
          console.error(e)
        }
      })
    },
  },
}
</script>

<style lang='scss' scoped>
.header-button-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.notification-container {
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    width: 16px;
    height: 16px;
  }

  p {
    max-width: 410px;
    margin-bottom: 0;
  }
}
.search-title {
  margin-bottom: 0;
  font-size: 1rem;
}
.custom-search {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 1rem;
    gap: 15px;

    .form-group {
      margin-bottom: 0;
    }
}
</style>

  <style lang='scss'>
  .edit-button {
    padding: 0.65rem 1.28rem;
    display: flex;
    align-items: center;

    &:hover {
      background-color: rgba(115, 103, 240, 0.12);
    }
  }

  @import '@core/scss/vue/libs/vue-good-table.scss';
  </style>
