<template>
  <li
    style="list-style-type: none; padding: 8px 0px 0px"
  >
    <div
      class="text-drk"
      :class="dynamicClass"
      @click="changeId(category.id)"
    >
      {{ category.title }}
    </div>
    <ul
      v-if="category.children && category.children.length > 0"
      v-show="openStatus"
      class="list-group options"
    >
      <template v-for="subcategory in category.children">
        <!-- @sendTitle="handlerSendTitle" -->
        <category-item
          :category="subcategory"
          :isLow="true"
        />
      </template>
    </ul>
  </li>
</template>

<script>
export default {
  name: 'CategoryItem',
  components: {
    'category-item': () => import('./CategoryItem.vue'),
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    isLow: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      openStatus: false,
    }
  },
  computed: {
    dynamicClass() {
      return {
        'text-gray': this.isLow,
        open: Number(this.$route.query.category_id) === this.category.id,
      }
    },
  },
  methods: {
    // handlerSendTitle(value) {
    //   this.$emit('sendTitle', value || this.category.title)
    // },
    changeId(id) {
      this.openStatus = !this.openStatus
      const queryParams = {
        ...this.$route.query,
      }
      if (Number(queryParams.category_id) === id) {
        delete queryParams.category_id
      } else {
        queryParams.category_id = id
      }
      this.$router.push({
        path: this.$route.path,
        query: {
          ...queryParams,
        },
      })
    },
  },
}
</script>

<style scoped>
.text-gray {
  color: #6c757d !important;
}

.text-drk {
  color: #161616;
}

.open {
  color: white !important;
  padding: 5px;
  background-color: #007bff;
}
</style>
