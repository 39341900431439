<template>
  <div class="distance">
    <div class="d-flex flex-column mt-2">
      <b-form-input
        v-model="distance.description"
        placeholder="Описание"
        vs-placeholder="Описание"
        class="inline-block mb-1"
      />
      <price-input-wrapper
        :value="distance.static_price"
        @input="value => distance.static_price = value"
      />
    </div>
    <div class="price mt-2">
      <h4 class="price__title">
        Доп. цены
      </h4>
      <div class="price__items mt-2">
        <div
          v-for="(item, i) in distance.date_prices"
          :key="'price_' + i"
          class="price__item mt-1"
        >
          <div
            class="price__item-del"
          >
            <svg
              v-if="!disabledDatePrices.some(e => e.id === item.id)"
              width="10"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              style="cursor:pointer"
              @click="distance.date_prices.splice(i,1)"
            ><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" /></svg>
          </div>
          <flat-pickr
            v-model="item.date_from"
            placeholder="От"
            class="form-control"
            :config="{
              ...config,
              defaultHour: 0
            }"
          />
          <flat-pickr
            v-model="item.date_to"
            placeholder="До"
            class="form-control"
            :config="{
              ...config,
              defaultHour: 23,
              defaultMinute: 59
            }"
          />
          <price-input-wrapper
            :value="item.price"
            @input="value => item.price = value"
          />
        </div>
      </div>

      <b-button
        type="submit"
        variant="primary"
        class="mt-1"
        @click="addPrices(distance)"
      >
        Добавить цену
      </b-button>
    </div>

    <div class="inline-block mt-2">
      <b-form-input
        v-model.number="distance.slots"
        :disabled="distance.infinite_slots"
        placeholder="Кол-во слотов"
        label="Слотов:"
        class="full-width"
      />
      <div
        class="flex mt-2 mb-3"
        style="align-items: center"
      >
        <b-form-checkbox
          v-model="distance.infinite_slots"
          class="custom-control-primary"
          checked="false"
          name="check-reg"
          switch
        />
        <span class="ml-1">Неограниченное кол-во слотов</span>
      </div>
    </div>

    <div class="add-fille mb-2">
      Прикрепить файл трека
    </div>
    <file-uploader
      v-model="distance.track_link_id"
      :default="distance.track_link"
      title="Загрузка файла"
    />
    <b-form-input
      v-model="distance.track_code"
      placeholder="Код виджета трека"
      class="mt-3"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BFormCheckbox, BButton, BModal, BFormRadio,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import FileUploader from '../../../global-components/FileUploader'
import PriceInputWrapper from '@/global-components/PriceInputWrapper.vue'

export default {
  name: 'Distances',
  components: {
    FileUploader,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BModal,
    flatPickr,
    BFormRadio,
    PriceInputWrapper,
  },
  props: ['distance', 'mode', 'start'],
  data() {
    return {
      disabledPrice: false,
      disabledDatePrices: [],
      config: {
        allowInput: true,
        altFormat: 'd.m.Y H:i',
        altInput: true,
        enableTime: true,
        dateFormat: 'Z',
        locale: Russian, // locale for this instance only
      },
    }
  },
  created() {
    if (this.start) {
      if (this.start.disabledDistances) {
        const disableDistance = this.start.disabledDistances.find(e => e.uuid === this.distance.uuid)
        if (disableDistance) {
          this.disabledPrice = true
          this.disabledDatePrices = disableDistance.date_prices || []
        }
      }
    }
  },
  methods: {
    addPrices(distance) {
      if (!distance.date_prices) {
        this.$set(distance, 'date_prices', [])
      }
      distance.date_prices.push({ date_to: '', date_from: '', value: '' })
    },
  },
}
</script>
