<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Общая информация
        </h4>
      </div>
      <div class="card-body">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Название"
              >
                <b-form-input
                  v-model="category.title"
                  placeholder="Категория"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>

    <div class="card">
      <div v-if="image" class="card-body">
        <img :src="image" alt="avatar">
      </div>
      <div class="card-body">
        <div class="upload-wrap">
          <file-uploader
            v-model="category.imageId"
            title="Картинка"
            @updatePhotos="getFile"
          />
        </div>
      </div>
    </div>

    <b-col>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="mr-1 save-button"
        :disabled="isloading"
        @click="saveItem"
      >
        <b-spinner
          v-if="isloading"
          small
        />
        {{ isloading ? 'Сохранение...' : 'Сохранить' }}
      </b-button>
    </b-col>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
  VBPopover,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import crudModule from '../../../libs/crud_module'
import FileUploader from '@/global-components/FileUploader'

const categoryModule = crudModule({
  entity_name: 'question-category',
})

export default {
  name: 'QuestionCategoryPage',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BSpinner,
    FileUploader
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      config: {
        allowInput: true,
        altFormat: 'd.m.Y H:i',
        altInput: true,
        enableTime: true,
        dateFormat: 'Z',
        locale: Russian, // locale for this instance only
      },
      isloading: false,
      category: {},
      image: null,
    }
  },
  async created() {
    if (this.$route.params.id === 'new') {
      return
    }
    try {
      await categoryModule.fetchItem(this.$route.params.id, {
        query: {
          admin: '1',
        },
      }).then(result => { 
        this.category = result.data 
        this.image = result.data.image.fullPath
      })
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    saveItem() {
      if (this.$route.params.id === 'new') {
        this.create()
      } else {
        this.update()
      }
    },
    getFile(serverFile) {
      this.image = serverFile?.fullPath
    },
    async update() {
      try {
        await categoryModule.updateItem(this.category.id, this.category)
          .then(response => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Категория сохранена',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'questions-categories' })
            return response
          })
          .catch(error => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      } catch (e) {
        this.isloading = false
        console.error(e)
      }
    },
    async create() {
      try {
        await categoryModule.createItem(this.category)
          .then(response => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Категоря добавлена',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'questions-categories' })
            return response
          })
          .catch(error => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      } catch (e) {
        this.isloading = false
        console.error(e)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
img {
  height: 200px;
  width: 200px;
}
</style>
