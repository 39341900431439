<template>
  <div class="card">
    <div class="card-body">
      <div class="custom-search d-flex justify-content-between align-items-center">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1 search-title">Поиск</label>
            <b-form-input
              v-model="searchData"
              placeholder="Поиск"
              type="text"
              class="d-inline-block"
              @update="(search, loading) => searchPosts(search, loading)"
            />
          </div>
        </b-form-group>
      </div>

      <vue-good-table
        :columns="columns"
        :rows="rows"
        :sort-options="{
          enabled: false,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'userData'">
            {{ props.row.createdBy.name }} {{ props.row.createdBy.surname }}
          </span>

          <span v-else-if="props.column.field === 'createdAt'">
            {{ $moment(props.row.createdAt).format('DD/MM/YYYY') }}
          </span>

          <span v-else-if="props.column.field === 'phoneNumber'">
            {{ props.row.createdBy.number ? `+${props.row.createdBy.number}` : '-' }}
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-b-modal.modal-primary
                  variant="outline-primary"
                  @click.prevent="redirectToPost()"
                >
                  <router-link
                    :to="{name: 'post', params:{id: props.row.id}}"
                    class="edit-button"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Редактировать</span>
                  </router-link>
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.modal-primary
                  variant="outline-primary"
                  @click.prevent="openModal(props.row.id)"
                >
                  <span>Удалить</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
      <infinite-loading
        spinner="bubbles"
        @infinite="infiniteScroll"
      >
        <div slot="no-more" />
        <div slot="no-results" />
      </infinite-loading>
    </div>

    <DeleteModal
      :modal-is-open="deleteModalIsOpen"
      :data-id="selectedRowId"
      modal-title="Удалить публикацию"
      @close="deleteModalIsOpen = false"
      @delete="deletePost"
    >
      <p>
        Вы уверены, что хотите удалить публикацию? Это действие нельзя отменить.
      </p>
    </DeleteModal>
  </div>
</template>

<script>
import {
  BDropdown,
  BFormGroup,
  BFormInput,
  BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import InfiniteLoading from 'vue-infinite-loading'
import crudModule from '../../libs/crud_module'
import DeleteModal from './DeleteModal.vue'
import ToastificationContent from '../../@core/components/toastification/ToastificationContent.vue'

const postsModule = crudModule({
  entity_name: 'post/admin',
})

const postModule = crudModule({
  entity_name: 'post',
})

export default {
  components: {
    VueGoodTable,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    InfiniteLoading,
    DeleteModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      skipCount: 0,
      maxResultCount: 10,
      searchData: '',
      deleteModalIsOpen: false,
      selectedRowId: 0,
      scrollState: undefined,
      columns: [
        {
          label: 'Пользователь',
          field: 'userData',
        },
        {
          label: 'Номер телефона',
          field: 'phoneNumber',
        },
        {
          label: 'Название',
          field: 'title',
        },
        {
          label: 'Статус',
          field: 'status',
        },
        {
          label: 'Дата создания',
          field: 'createdAt',
        },
        {
          label: 'Действие',
          field: 'action',
        },
      ],
      rows: [],
    }
  },
  methods: {
    async infiniteScroll($state) {
      this.scrollState = $state
      const payload = {
        filterText: this.searchData,
        skipCount: this.skipCount,
        maxResultCount: this.maxResultCount,
      }
      await postsModule.fetchItems(payload)
        .then(res => {
          if (res.data.rows.length > 0) {
            this.rows.push(...res.data.rows)
            this.skipCount += this.maxResultCount
            $state.loaded()
          } else {
            $state.complete()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    openModal(id) {
      this.selectedRowId = id
      this.deleteModalIsOpen = true
    },
    async deletePost(id) {
      await postModule.deleteItem(id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'BellIcon',
              text: 'Пост успешно удален',
              variant: 'success',
            },
          })
          this.deleteModalIsOpen = false
          const postIndex = this.rows.findIndex(row => row.id === id)
          this.rows.splice(postIndex, 1)
        })
        .catch(err => {
          console.log(err)
        })
    },
    redirectToPost(id) {

    },
    debounceSearch(search, loading, fn) {
      clearTimeout(this.debounce)
      if (search.length >= 0) {
        this.debounce = setTimeout(fn, 600)
      }
    },
    searchPosts(search, loading) {
      this.debounceSearch(search, loading, async () => {
        try {
          await postsModule.fetchItems({
            filterText: search,
            skipCount: 0,
            maxResultCount: this.maxResultCount,
          })
            .then(result => {
              this.rows = result.data.rows
              if (this.scrollState) {
                this.scrollState.reset()
              }
            })
        } catch (e) {
          console.error(e)
        }
      })
    },
  },
}
</script>
<style lang='scss' scoped>
    .search-title {
        margin-bottom: 0;
        font-size: 1rem;
    }
    .custom-search {
        width: 100%;
        justify-content: space-between;
        margin-bottom: 1rem;
        gap: 15px;

        .form-group {
            margin-bottom: 0;
        }
    }
</style>

<style lang='scss'>
    @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
