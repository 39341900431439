<template>
  <div>
    <b-button
      v-b-modal.modal-2
      variant="primary"
      class="mr-1"
    >
      Загрузить из шаблона
    </b-button>
    <b-modal
      id="modal-2"
      ref="group-modal"
      title="Шаблоны групп"
      hide-footer
      no-close-on-backdrop
    >
      <v-select
        v-model="selected"
        label="name"
        :options="groupTemplates"
      />
      <b-button
        variant="primary"
        class="mr-1 mt-2"
        :disabled="!selected"
        @click="updateGroup"
      >
        Сохранить
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import Ripple from 'vue-ripple-directive'
import crud_module from '@/libs/crud_module'

const group_module = crud_module({
  entity_name: 'group-template',
})
export default {
  name: 'GroupTemplates',
  components: {
    vSelect,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      groupTemplates: [],
      selected: null,
    }
  },
  created() {
    this.getTemplates()
  },
  methods: {
    async getTemplates() {
      this.groupTemplates = await group_module.fetchItems().then(r => r.data.rows)
    },
    updateGroup() {
      this.$emit('select', this.selected.instances)
      this.selected = null
      this.$refs['group-modal'].hide()
    },
  },
}
</script>
