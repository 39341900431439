<template>
  <div>
    <div class="mt-3">
      Поле {{ fieldIndex + 1 }}
      <div
        :id="`button-delete-additional-field-tooltip-${fieldIndex + 1}`"
        class="d-inline-block"
        tabindex="0"
      >
        <b-button
          title="Удалить"
          variant="flat-danger"
          class="btn-icon inline-block"
          size="sm"
          :disabled="isRestricted"
          @click="deleteField"
        >
          <feather-icon icon="MinusIcon" />
        </b-button>
      </div>
      <b-tooltip
        v-if="isRestricted"
        :target="`button-delete-additional-field-tooltip-${fieldIndex + 1}`"
        triggers="hover"
      >
        В данной дистанции есть регистрации
      </b-tooltip>
    </div>

    <template v-if="fieldState">
      <b-form-checkbox
        v-model="fieldState.is_optional"
        default="false"
        class="mt-1"
      >
        Сделать поле необязательным?
      </b-form-checkbox>
      <div
        :key="'field.name' + fieldIndex"
        class="mt-2 mb-2"
      >
        Название поля

        <b-form-input
          :key="'field.name_' + fieldIndex"
          v-model="fieldState.name"
          placeholder="Название"
          class="inline-block"
          style="margin-top: 10px!important"
        />
      </div>

      <b-form-checkbox
        v-model="fieldState.is_public"
        default="false"
      >Показывать ответы в результатах?
      </b-form-checkbox>

      <div
        v-if="options.length > 0"
        :key="'field.parent' + fieldIndex"
        class="mt-2 mb-2"
      >
        Зависимость от:
        <v-select
          v-model="fieldState.parent_uuid"
          placeholder="Выберите вопрос"
          :reduce="option => option.uuid"
          :options="options"
          label="name"
          style="margin-top: 10px!important"
          @input="changeParent"
        />

        <div
          v-if="fieldState.parent && fieldState.parent.type === 'Синглселект'"
          class="mt-1"
        >
          Выберите значение
          <v-select
            v-model="fieldState.expected_answer_uuid"
            :reduce="option => option.uuid"
            style="margin-top: 10px!important"
            label="title"
            :options="fieldState.parent.options"
          />
        </div>
      </div>

      <div
        :key="'field.type' + fieldIndex"
        class="mt-2 mb-2"
      >
        Тип поля
        <v-select
          v-model="fieldState.type"
          style="margin-top: 10px!important"
          label="value"
          :options="fieldType"
          :reduce="option => option.value"
        />
      </div>
      <div
        v-if="fieldState.options && (fieldState.type === 'Синглселект' || fieldState.type === 'Мультиселект')"
        class="mt-2 mb-2"
      >
        <p>Добавленные опции</p>
        <div
          v-for="(option, index) in fieldState.options"
          :key="option.title"
          class="added-option"
        >
          <b-button
            title="Удалить"
            variant="flat-danger"
            class="btn-icon inline-block"
            size="sm"
            @click="() => removeSingleOption(index)"
          >
            <feather-icon icon="MinusIcon" />
          </b-button>
          <div
            v-if="editedOptionIndex === index"
            class="option-content"
          >
            <b-form-input
              id="option-name"
              v-model="editedOption.title"
              type="text"
              placeholder="Название"
              class="inline-block"
            />
            <price-input-wrapper
              :value="editedOption.price"
              @input="value => editedOption.price = value"
            />
            <b-button
              title="Добавить"
              variant="primary"
              class="add-button btn-icon inline-block"
              size="sm"
              @click="saveEditedOption"
            >
              <feather-icon
                icon="Edit2Icon"
              />
            </b-button>
          </div>
          <div
            v-else
            class="option-content"
          >
            <p>{{ option.title }} <span v-if="option.price"> - {{ option.price / 100 }} рублей</span></p>
            <feather-icon
              icon="Edit2Icon"
              class="edit-icon"
              @click="editOption(index)"
            />
          </div>
        </div>
      </div>
      <div v-if="fieldState.type === 'Синглселект' || fieldState.type === 'Мультиселект'">
        <div class="row options-row">
          <div class="col-6">
            <label for="option-name">Название опции</label>
            <b-form-input
              id="option-name"
              v-model="optionName"
              type="text"
              no-wheel
              placeholder="Название опции"
              class="inline-block"
            />
          </div>
          <div class="col-4">
            <label for="option-price">Цена</label>
            <price-input-wrapper
              :value="optionPrice"
              @input="value => optionPrice = value"
            />
          </div>
          <b-button
            title="Добавить"
            variant="primary"
            class="add-button btn-icon inline-block"
            size="sm"
            :disabled="!optionName"
            @click="addOption"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </div>
      </div>

      <div
        v-if="fieldState.type === 'Чекбокс'"
        :key="'field.price?' + fieldIndex"
        class="mt-2 mb-2"
      >
        <b-form-checkbox
          v-model="fieldState.priceBoolean"
          default="false"
          @input="resetPrice"
        >Цена?
        </b-form-checkbox>

        <div
          v-if="fieldState.priceBoolean === true || fieldState.price"
          class="mt-1"
        >
          <price-input-wrapper
            :value="fieldState.price"
            @input="value => fieldState.price = value"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  BFormInput, BFormCheckbox, BButton, BFormCheckboxGroup, BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import crud_module from '../../../libs/crud_module'
import PriceInputWrapper from '@/global-components/PriceInputWrapper.vue'
import { v4 as uuidv4 } from 'uuid'

const productsModule = crud_module({
  entity_name: 'product',
})

export default {
  name: 'AdditionalField',
  components: {
    BFormInput,
    BFormCheckbox,
    BButton,
    BFormCheckboxGroup,
    BTooltip,
    vSelect,
    PriceInputWrapper,
  },
  props: ['field', 'fieldIndex', 'stage', 'distance', 'isRestricted'],
  data() {
    return {
      fieldState: this.additionalField,
      fieldType: [{ name: 'STRING', value: 'Текст' }, { name: 'NUMBER', value: 'Число' }, { name: 'BOOLEAN', value: 'Чекбокс' },
        { name: 'DATE', value: 'Дата' }, { name: 'MULTI_SELECT', value: 'Мультиселект' }, { name: 'SELECT', value: 'Синглселект' },
        { name: 'TIME', value: 'Время' }],
      products: [],
      debounce: null,
      options: [],
      showSelect: null,
      optionName: '',
      optionPrice: null,
      editedOptionIndex: null,
      editedOption: {
        title: '',
        price: null,
      },
    }
  },
  watch: {
    field: {
      handler() {
        this.fieldState = this.field
      },
      immediate: true,
    },
    fieldState: {
      handler(newValue, prev) {
        this.$emit('update-field', this.fieldState, this.fieldIndex, this.stage)
      },
      deep: true,
    },
  },
  async created() {
    if (this.fieldState.price) {
      this.fieldState.priceBoolean = true
    }
    if (this.stage) {
      this.options = this.stage.additional_fields.filter(e => e.uuid !== this.fieldState.uuid && (e.type === 'Чекбокс' || e.type === 'Синглселект'))
    } else {
      this.options = this.distance.additional_fields.filter(e => e.uuid !== this.fieldState.uuid && (e.type === 'Чекбокс' || e.type === 'Синглселект'))
    }
    if (this.fieldState.parent_uuid) {
      if (this.stage) {
        this.fieldState.parent = this.stage.additional_fields.filter(field => field.uuid === this.fieldState.parent_uuid)[0]
      } else if (this.distance) {
        this.fieldState.parent = this.distance.additional_fields.filter(field => field.uuid === this.fieldState.parent_uuid)[0]
      }
    }
    // console.log('Field state', this.fieldState)
  },
  methods: {
    deleteField() {
      this.$emit('delete-field', this.fieldIndex, this.stage)
    },
    resetPrice() {
      this.fieldState.price = null
    },
    debounceSearch(search, loading, fn) {
      clearTimeout(this.debounce)
      if (search.length > 0) {
        this.debounce = setTimeout(fn, 600)
      }
    },
    removeSingleOption(index) {
      this.fieldState.options.splice(index, 1)
    },
    editOption(index) {
      this.editedOptionIndex = index
      this.editedOption = {
        ...this.fieldState.options[index],
        price: this.fieldState.options[index].price ? this.fieldState.options[index].price : null,
      }
    },
    saveEditedOption() {
      this.$set(this.fieldState.options, this.editedOptionIndex, {
        ...this.editedOption,
        price: this.editedOption.price ? this.editedOption.price : null,
      })
      this.editedOptionIndex = null
    },
    addOption() {
      if (!this.fieldState.options) {
        this.fieldState.options = []
      }
      this.fieldState.options.push({
        uuid: uuidv4(),
        title: this.optionName,
        price: this.optionPrice ? this.optionPrice : null,
      })
      this.optionName = ''
      this.optionPrice = null
    },
    addMultipleOption(option) {
      if (!this.fieldState.options) {
        this.fieldState.options = []
      }
      this.fieldState.options = option.map(item => ({ title: item, price: null }))
    },
    getProducts(search, loading) {
      this.debounceSearch(search, loading, async () => {
        try {
          await productsModule.fetchItems({ search, limit: 10 })
            .then(result => {
              this.products = result.data.rows
              loading(false)
            })
        } catch (e) {
          console.error(e)
        }
      })
    },
    changeParent(e) {
      if (this.stage) {
        const selected = this.stage.additional_fields.filter(field => field.uuid === e)[0]
        this.fieldState.parent = selected
        if (selected?.type === 'Чекбокс') {
          this.fieldState.expected_answer = 'true'
        }
      } else {
        const selected = this.distance.additional_fields.filter(field => field.uuid === e)[0]
        this.fieldState.parent = selected
        if (selected?.type === 'Чекбокс') {
          this.fieldState.expected_answer = 'true'
        }
      }
    },
  },
}
</script>

<style lang="scss">
.added-option {
  display: flex;
  align-items: center;
  gap: 5px;

  p {
    margin-bottom: 0;
  }
}

.option-content {
  display: flex;
  align-items: center;
  gap: 5px;

  .edit-icon {
    cursor: pointer;

    &:hover {
      color: #7367f0;
    }
  }
}
.options-row {
  display: flex;
  align-items: center;

  .add-button {
    margin-left: 14px;
    margin-top: 23px;
    width: 30px;
    height: 30px;
  }
}
</style>
