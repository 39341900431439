<template>
  <div>
    <b-modal
      v-if="distance.related_distances"
      :id="'bv-modal-edit' + index"
      class="z-index"
      title="Редактировать комбо дистанций"
      hide-footer
      @show="updateSelected"
    >
      <b-form-input
        v-model="distance.name"
        placeholder="Название"
        label="Название"
        vs-placeholder="Название"
        class="w-full"
      />
      <b-form-group
        id="combo-multi-registration"
        label="Отключить проверку дублей?"
        class="mt-1"
      >
        <b-form-checkbox
          v-model="distance.is_multi_registration"
          checked="false"
          class="custom-control-primary"
          name="check-view"
          switch
        />
      </b-form-group>
      <b-tooltip
        target="combo-multi-registration"
      >
        Отключение дубликатов не будет влиять на комбо, в которых есть эстафеты
      </b-tooltip>
      <b-form-checkbox-group
        v-for="(distance, index) in filteredDistances"
        :key="index"
        v-model="selectedDistances"
      >
        <div
          :id="`checkbox-tooltip-${index}`"
          class="d-inline-block"
          tabindex="0"
        >
          <b-form-checkbox
            :id="`checkbox-tooltip-${index}`"
            :key="index"
            class="mt-1 mr-1"
            :value="distance"
            :disabled="isRestricted"
          >
            {{ distance.name }}
          </b-form-checkbox>
        </div>
        <b-tooltip
          v-if="isRestricted"
          :target="`checkbox-tooltip-${index}`"
          triggers="hover"
        >
          В данной дистанции есть регистрации
        </b-tooltip>
      </b-form-checkbox-group>

      <discount-switcher
        title="Скидка за комбо"
        :is-restricted="isRestricted"
        :discount-is-percent="distance.combo_discount_is_percent"
        :value="+distance.combo_discount_value"
        @discountType="value => distance.combo_discount_is_percent = value"
        @input="value => distance.combo_discount_value = value"
      />
      <span
        id="save-button"
        class="d-inline-block button-container"
      >
        <b-button
          id="tooltip-target-1"
          type="submit"
          variant="primary"
          class="mr-1 combo-button"
          :disabled="selectedDistances.length < 2"
          @click="updateSport"
        >
          Сохранить
        </b-button>
      </span>
      <b-tooltip
        v-if="selectedDistances.length < 2"
        target="save-button"
      >
        Комбо должно содержать минимум 2 дистанции
      </b-tooltip>
    </b-modal>
    <b-modal
      v-else
      :id="'bv-modal-edit' + index"
      class="z-index"
      title="Редактировать дистанцию"
      ok-only
      ok-title="Сохранить"
      @ok="updateSport"
      @show="updateSelected"
    >
      <div class="con-example-prompt">
        <b-form-input
          v-model="distance.name"
          placeholder="Новая дистанция"
          label="Новая дистанция"
          vs-placeholder="Новая дистанция"
          class="w-full"
        />
        <div class="mt-1">
          <b-form-group
            label="Отключить проверку дублей?"
          >
            <b-form-checkbox
              v-model="distance.is_multi_registration"
              checked="false"
              class="custom-control-primary"
              name="check-view"
              switch
            />
          </b-form-group>
        </div>
        <div class="mt-3 mb-3 title-format">
          Формат
        </div>
        <div
          v-for="(format, index) in sport_formats"
          :key="'format' + index"
          class="mb-1"
        >
          <b-form-radio
            v-model="distance.format"
            name="sport_format"
            class="mr-6"
            :value="format"
          >
            {{ format }}
          </b-form-radio>
        </div>
      </div>

      <template v-if="distance.format === 'Лично'">
        <div>
          <distance-block
            :distance="distance"
            :mode="'Лично'"
            :start="start"
          />
          <div class="mt-3 mb-3 mt-3">
            Группы ({{ distance.groups.length }})
          </div>
          <template v-for="(group, index) in distance.groups">
            <group
              :key="'group' + index"
              :groups="distance.groups"
              :group="group"
              :group-index="index"
              :is-restricted="isRestricted"
              @update-group="updateGroup"
              @delete-group="deleteGroup"
            />
          </template>
          <div
            id="button-tooltip"
            class="inline-block mt-3 group-buttons"
            tabindex="0"
          >
            <group-templates @select="addGroupsFromTemplate" />
            <b-button
              size="small"
              :disabled="isRestricted"
              @click="addGroup"
            >
              Добавить группу
            </b-button>
          </div>
          <b-tooltip
            v-if="isRestricted"
            target="button-tooltip"
            triggers="hover"
          >
            В данной дистанции есть регистрации
          </b-tooltip>
        </div>
      </template>

      <template v-if="distance.format === 'Эстафета'">
        <distance-block
          :distance="distance"
          :start="start"
        />
        <div class="mt-3 mb-3 mt-3">
          Этапы ({{ distance.stages.length }})
        </div>
        <stage-block
          :stages="distance.stages"
          :is-restricted="isRestricted"
        />
        <div
          id="add-stage-button-tooltip"
          class="d-inline-block"
          tabindex="0"
        >
          <b-button
            class="mt-3"
            size="small"
            :disabled="isRestricted"
            @click="addStage"
          >
            Добавить этап
          </b-button>
        </div>
        <b-tooltip
          v-if="isRestricted"
          target="add-stage-button-tooltip"
          triggers="hover"
        >
          В данной дистанции есть регистрации
        </b-tooltip>

        <div class="mt-3 mb-3 mt-3">
          Группы ({{ distance.groups.length }})
        </div>
        <template v-for="(group, index) in distance.groups">
          <group
            :key="'group' + index"
            :groups="distance.groups"
            :group="group"
            :group-index="index"
            :distance-format="distance.format"
            :stages="distance.stages"
            @update-group="updateGroup"
            @delete-group="deleteGroup"
          />
        </template>
        <div
          id="add-group-button-tooltip"
          class="inline-block mt-3 group-buttons"
          tabindex="0"
        >
          <group-templates @select="addGroupsFromTemplate" />
          <b-button
            size="small"
            @click="addGroup"
          >
            Добавить группу
          </b-button>
        </div>
        <b-tooltip
          v-if="isRestricted"
          target="add-group-button-tooltip"
          triggers="hover"
        >
          В данной дистанции есть регистрации
        </b-tooltip>
      </template>
      <template>
        <div>
          <div class="mt-1 mb-1 mt-2">
            Дополнительные поля ({{ distance.additional_fields.length }})
          </div>
          <template v-for="(additionalField, index) in distance.additional_fields">
            <additionalField
              :key="'additionalField' + index"
              :distance="distance"
              :field="additionalField"
              :is-restricted="isRestricted"
              :field-index="index"
              @update-field="updateField"
              @delete-field="deleteField"
            />
          </template>
          <div
            id="additional-field-button-tooltip"
            class="d-inline-block"
            tabindex="0"
          >
            <b-button
              class="mt-1"
              size="small"
              :disabled="isRestricted"
              @click="addField"
            >
              Добавить доп поле
            </b-button>
          </div>
          <b-tooltip
            v-if="isRestricted"
            target="additional-field-button-tooltip"
            triggers="hover"
          >
            В данной дистанции есть регистрации
          </b-tooltip>
        </div>
      </template>
      <div class="mt-2 mb-1">
        Скидки по возрасту
      </div>
      <div>
        <div class="discount__items">
          <div
            v-for="(item, i) in distance.discounts"
            :key="'discount_' + i"
            class="discount__item"
          >
            <div
              v-if="!disabledDiscounts.includes(item.id)"
              class="discount__item-del"
              style="margin-bottom: 10px"
              @click="distance.discounts.splice(i, 1)"
            >
              <svg
                width="10"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path
                  d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                />
              </svg>
            </div>
            <b-form-input
              v-model.number="item.c_from"
              type="number"
              no-wheel
              placeholder="От"
              style="margin-bottom: 10px"
            />
            <b-form-input
              v-model.number="item.c_to"
              type="number"
              no-wheel
              placeholder="До"
              style="margin-bottom: 10px"
            />
            <discount-switcher
              :discount-is-percent="item.percent"
              :value="+item.value"
              @discountType="value => item.percent = value"
              @input="value => item.value = value"
            />
          </div>
        </div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mt-1"
          @click="
            distance.discounts.push({
              c_to: null,
              c_from: null,
              value: null,
              percent: false,
            })
          "
        >
          Добавить скидку
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormInput, BFormCheckbox, BButton, BModal, BFormRadio, BFormCheckboxGroup, VBPopover, BFormGroup,
  BTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { v4 as uuidv4 } from 'uuid'
import Vue from 'vue'
import GroupTemplates from '@/views/starts/GroupTemplates.vue'
import DistanceBlock from './DistanceBlock.vue'
import Group from './Group.vue'
import StageBlock from './StageBlock.vue'
import AdditionalField from './AdditionalField.vue'
import DiscountSwitcher from '@/global-components/DiscountSwitcher.vue'

export default {
  name: 'EditDistance',
  components: {
    StageBlock,
    Group,
    DistanceBlock,
    GroupTemplates,
    BFormInput,
    BFormCheckbox,
    BButton,
    BModal,
    BFormRadio,
    BFormCheckboxGroup,
    AdditionalField,
    BTooltip,
    DiscountSwitcher,
    BFormGroup,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: ['age_groups', 'select_kinds_sport', 'sport', 'index', 'start', 'isRestricted'],
  data() {
    return {
      distance: {},
      groups: [],
      slotDisable: false,
      kind_sport: '',
      activeSelectSport: false,
      new_kind_sport: '',
      sport_formats: [
        'Лично',
        'Эстафета',
      ],
      selectedDistances: [],
      disabledCombo: [],
      disabledGroups: [],
      disabledStages: [],
      disabledDiscounts: [],
      templateGroups: [],
    }
  },
  computed: {
    filteredDistances() {
      return this.select_kinds_sport.filter(item => !item.related_distances)
    },
  },
  watch: {
    'distance.format': {
      handler(newValue) {
        if (newValue === 'Лично') {
          this.distance.groups = this.distance.groups.filter(group => group.format !== 'Minimum' && group.format !== 'Combined')
        } else if (newValue === 'Эстафета') {
          if (this.templateGroups.length) {
            const nedeedGroups = this.templateGroups.filter(group => group.format === 'Minimum' || group.format === 'Combined')
            this.distance.groups = this.distance.groups.concat(nedeedGroups)
          }
        }
      },
    },
  },
  created() {
    this.transformSelectedDistances()

    if (this.sport.groups) {
      this.transformSportGroupsFields()
    }
    this.distance = JSON.parse(JSON.stringify(this.sport))
  },
  mounted() {
  },
  methods: {
    addStage() {
      this.distance.stages.push(
        {
          uuid: uuidv4(),
          name: null,
          sex: null,
          additional_fields: [],
          kind_of_sport_id: null,
          stage_length: null,
        },
      )
    },
    addGroupsFromTemplate(groups) {
      if (this.templateGroups.length !== 0) {
        const { template_id } = this.templateGroups[0]
        this.distance.groups = this.distance.groups.filter(group => group.template_id !== template_id)
      }
      this.templateGroups = groups
      if (this.distance.format === 'Лично') {
        const filteredGroups = groups
          .filter(group => group.format === 'Male' || group.format === 'Female' || group.format === 'Any')
        this.distance.groups = this.distance.groups.concat(filteredGroups)
      } else if (this.distance.format === 'Эстафета') {
        this.distance.groups = this.distance.groups.concat(groups)
      }
    },
    updateDiscountType(value) {
      this.distance.combo_discount_is_percent = value
    },
    updateDiscountValue(value) {
      this.distance.combo_discount_value = value
    },
    updateSelected() {
      this.distance = JSON.parse(JSON.stringify(this.sport))
      this.transformSelectedDistances()
    },
    addGroup() {
      if (this.distance.format === 'Лично') {
        this.distance.groups.push({
          name: null, age_from: null, age_to: null, age_reference_date: null,
        })
      } else if (this.distance.format === 'Эстафета') {
        this.distance.groups.push({
          name: null,
          age_from: null,
          age_to: null,
          age_reference_date: null,
          combination_dict: {},
        })
      }
    },
    updateSport() {
      if (this.distance.related_distances) {
        this.distance.related_distances = this.selectedDistances.map(e => e.uuid)
      } else {
        if (this.distance.format === 'Эстафета' && this.distance.groups.length > 0) {
          this.transformGroupsFields()
        }
        this.sport.name = this.distance.name
        this.sport.format = this.distance.format
        this.sport.static_price = this.distance.static_price
        this.sport.description = this.distance.description
        this.sport.date_prices = this.distance.date_prices
        this.sport.stages = this.distance.stages
        this.sport.slots = this.distance.slots
        this.sport.infinite_slots = this.distance.infinite_slots
        this.sport.additional_fields = this.distance.additional_fields
        this.sport.discounts = this.distance.discounts
        this.distance.groups = this.distance.groups.map(obj => {
          if (obj.template_id) {
            const { id, ...rest } = obj
            return rest
          }
          return obj
        })
        this.sport.groups = this.distance.groups
      }
      this.select_kinds_sport[this.index] = this.distance
      this.$bvModal.hide(`bv-modal-edit${this.index}`)
    },
    updateGroup(group, groupIndex) {
      this.distance.groups[groupIndex] = group
    },
    transformGroupsFields() {
      this.distance.groups.forEach(group => {
        if (group.combination_dict) {
          if (Object.keys(group.combination_dict).length > 0) {
            group.combination_list = []
            for (const combinationDictKey in group.combination_dict) {
              group.combination_list.push({
                stageUUID: combinationDictKey,
                sex: group.combination_dict[combinationDictKey].length > 1 || !group.combination_dict[combinationDictKey] ? 'Any' : group.combination_dict[combinationDictKey][0],
              })
            }
          }
        }
        delete group.combination_dict
      })
    },
    transformSportGroupsFields() {
      this.sport.groups.forEach(group => {
        if (group.combination_list) {
          group.combination_dict = {}
          group.combination_list.forEach(item => {
            if (item.sex === 'Any') {
              Vue.set(group.combination_dict, item.stageUUID, ['Male', 'Female'])
              // group.combination_dict[item.stageUUID] = ['Male', 'Female']
            } else {
              Vue.set(group.combination_dict, item.stageUUID, [item.sex])
              // group.combination_dict[item.stageUUID] = [item.sex]
            }
          })
        }
      })
    },
    transformSelectedDistances() {
      if (this.sport.related_distances) {
        this.selectedDistances = this.select_kinds_sport.filter(e => this.sport.related_distances.includes(e.uuid))
      }
    },
    addPrices(distance) {
      if (!distance.prices) {
        this.$set(distance, 'prices', [])
      }
      distance.prices.push({ c_to: '', c_from: '', value: '' })
    },
    deleteGroup(group, indexGroup) {
      if (this.templateGroups.length && group.template_id) {
        const indexDeletedGroup = this.templateGroups.findIndex(item => item.template_id === group.template_id && item.id === group.id)
        this.templateGroups.splice(indexDeletedGroup, 1)
      }
      this.distance.groups.splice(indexGroup, 1)
    },
    addField() {
      const uuid = uuidv4()
      this.distance.additional_fields.push({
        name: '',
        type: '',
        uuid,
      })
    },
    updateField(field, fieldIndex) {
      this.distance.additional_fields[fieldIndex] = field
    },
    deleteField(fieldIndex) {
      this.distance.additional_fields.splice(fieldIndex, 1)
    },
    restrictEdit(disableDistance) {
      if (disableDistance.groups) {
        this.disabledGroups = disableDistance.groups
      }
      if (disableDistance.stages) {
        this.disabledStages = disableDistance.stages.map(e => e.id)
      }
      if (disableDistance.discounts) {
        this.disabledDiscounts = disableDistance.discounts.map(e => e.id)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.button-container {
  float: right;
}
.group-buttons {
  display: flex;
}

.add-fille{
  margin: 10px 0;
}
.distance{
  margin-top: 30px;
}
.title-format{
  margin: 15px 0!important;
}
.input__code{
  margin-top: 15px!important;
}
.con-example-prompt{
  input{
    margin-top: 20px!important;
  }
}
.distances__minus{
  margin-left: 5px;
  transition: .3s;
  &:hover{
    cursor: pointer;
    transform: scale(1.1);
  }
}
    .sport-block {
        border-bottom: 1px solid #e5e5e5;
    }

    .z-index {
        z-index: 52011;
    }

.price{
  &__title{
    margin-top: 20px;
  }
  &__items{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px 20px -15px;
  }
  &__item{
    padding: 20px;
    border: 1px solid #00000012;
    border-radius: 20px;
    position: relative;
    width: calc(50% - 30px);
    margin: 10px 15px 0 15px;

    &-del{
      position: absolute;
      right: 20px;
      top: 10px;
    }
  }

}

</style>
<style>
.flex{
  display: flex;

}
.justify-end{

  justify-content: end;
}
.inline-block{
  display: inline-block;
}
.age{
  display: flex;

}
.age input:last-child{
  margin-left: 30px;
}
.price__item .form-control{
  margin-top: 20px;
}
</style>
