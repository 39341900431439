<template>
  <div>
    <div class="demo-inline-spacing" />
    <b-modal
      id="modal-primary"
      v-model="modalIsOpen"
      modal-class="modal-primary"
      centered
      :title="modalTitle"
    >
      <div class="notification-container">
        <feather-icon
          icon="AlertCircleIcon"
          size="16"
          color="#FFA500"
        />
        <slot />
      </div>
      <template #modal-footer="">
        <b-button
          class="mt-1"
          variant="outline-primary"
          @click="$emit('close')"
        >
          Отмена
        </b-button>
        <b-button
          class="mt-1"
          variant="primary"
          @click="createAction"
        >
          Продолжить
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  props: {
    dataId: {
      type: Number,
      required: true,
    },
    modalIsOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    modalTitle: {
      type: String,
      required: true,
    },
  },
  methods: {
    createAction() {
      this.$emit('delete', this.dataId)
    },
  },
}
</script>

<style lang="scss" scoped>
.notification-container {
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    width: 95%;
    margin-bottom: 0;
  }
}
</style>
