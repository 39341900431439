<template>
  <div class="card col-md-2 pl-0 flex-column">
    <div
      v-if="categories.length !== 0"
      class="px-1"
    >
      <div
        class=""
        @click="categoryStatus = !categoryStatus"
      >
        <h5>Категории</h5>
      </div>
      <div class="box-widget fl-wrap">
        <div
          v-if="categoryStatus"
          class="box-widget-content"
        >
          <ul class="user-select-none">
            <template v-for="category in categories">
              <!-- @sendTitle="handlerSendTitle"  -->
              <category-item :category="category" />
            </template>
          </ul>
        </div>
      </div>
    </div>
    <div class="scrl-content filter-sidebar fs-viscon">
      <h5 class="px-1">Фильтры</h5>
      <div class="px-1 d-flex flex-column">
        <div
          v-for="(option, index) in options"
          :key="'filter_' + index"
          class="user-select-none"
          style="padding: 8px 0px 0px"
          role="button"
        >
          <div
            v-if="option.in_filter"
            class="category"
            @click="toggleOption(index)"
          >
            {{ option.name }}
          </div>
          <ul
            class="list-group options"
            v-if="option.in_filter && index === openOptionIndex"
          >
            <li
              v-for="(optionValue, optionValueIdx) in option.values"
              :style="option.name === 'цвет' ? 'flex-wrap:wrap;' : ''"
              style="padding: 12px 7px"
              :key="'filter_' + optionValueIdx"
              class="list-group-item"
            >
              <b-form-checkbox
                :id="'filter_' + optionValueIdx + '_value' + optionValueIdx"
                v-model="selectedOptionList"
                :value="`${option.uuid}:${optionValue.uuid}`"
                class="custom-input"
              >
                {{ optionValue.name }}
              </b-form-checkbox>
            </li>
          </ul>
        </div>
      </div>
      <b-button
        class="ml-1"
        variant="outline-danger"
        @click="resetOptions"
      >
        <i class="far fa-redo"></i> Сбросить
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BCheckbox,
  BCollapse,
} from 'bootstrap-vue'
import CategoryItem from './CategoryItem.vue'
import axios from '@axios'
export default {
  components: {
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    BCheckbox,
    BButton,
    BCollapse,
    CategoryItem,
  },
  data() {
    return {
      openOptionIndex: null,
      selectedOptionList: [],
      options: [],
      categories: [],
      categoryStatus: true,
    }
  },
  watch: {
    selectedOptionList() {
      const selectedValueList = this.selectedOptionList
      const resultedFilterObj = selectedValueList.reduce((acc, cur) => {
        const [optionId, valueId] = cur.split(':')
        if (Array.isArray(acc[optionId])) {
          acc[optionId].push(valueId)
        } else {
          acc[optionId] = [optionId, valueId]
        }
        return acc
      }, {})

      const resultedFilterStr = Object.values(resultedFilterObj).map(
        valueList => `${valueList[0]}:${valueList.slice(1).join(',')}`,
      )

      const queryParams = {
        'options[]': resultedFilterStr,
        ...(this.$route.query.sorting
          ? { sorting: this.$route.query.sorting }
          : {}),
        ...(this.$route.query.category_id
          ? { category_id: this.$route.query.category_id }
          : {}),
      }

      this.$router.push({
        path: this.$route.path,
        query: {
          ...queryParams,
        },
      })
      this.$emit('trigerGetProducts')
    },
    '$route.query.category_id': {
      immediate: true,
      async handler(newCategoryId, oldCategoryId) {
        if (newCategoryId !== oldCategoryId) {
          await this.fetchFilters()
          this.$emit('trigerGetProducts')
        }
      },
    },
  },
  async mounted() {
    if (process.server) {
      return
    }
    await this.fetchFilters()
    await this.$store.dispatch('shop/fetchCategories', this.$route.query.category_id)
    this.categories = this.$store.getters['shop/GET_ALL_CATEGORIES']
  },
  methods: {
    toggleOption(index) {
      this.openOptionIndex = this.openOptionIndex === index ? null : index
    },
    resetOptions() {
      this.selectedOptionList = []
    },
    async fetchFilters() {
      try {
        const res = await axios.get('/product/render-filter', {
          params: {
            ...(this.$route.query.category_id
              ? { categoryId: this.$route.query.category_id }
              : {}),
          },
        })
        if (res?.data?.filters?.rows) {
          this.options = res.data.filters.rows
        } else {
          console.error('Unexpected response structure:', res)
        }
      } catch (error) {
        console.error('Error fetching filters:', error)
      }
    },
  },
}
</script>
