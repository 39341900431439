<template>
  <div>
    <div class="mt-3">
      Группа {{ groupIndex + 1 }}
      <div
        :id="`button-delete-tooltip-${groupIndex + 1}`"
        class="d-inline-block"
        tabindex="0"
      >
        <b-button
          title="Удалить"
          variant="flat-danger"
          class="btn-icon inline-block"
          size="sm"
          :disabled="isRestricted"
          @click="deleteGroup"
        >
          <feather-icon icon="MinusIcon" />
        </b-button>
      </div>
      <b-tooltip
        v-if="isRestricted"
        :target="`button-delete-tooltip-${groupIndex + 1}`"
        triggers="hover"
      >
        В данной дистанции есть регистрации
      </b-tooltip>
    </div>
    <template v-if="groupState">
      <b-form-input
        :key="'group.name_' + groupIndex"
        v-model="groupState.name"
        placeholder="Название"
        class="inline-block"
      />

      <div
        :key="'group.age_' + groupIndex"
        style="margin-top: 20px"
      >
        Возраст
      </div>
      <div
        :key="'group.from_to_' + groupIndex"
        class="age"
      >
        <b-form-input
          v-model.number="groupState.age_from"
          type="number"
          no-wheel
          style="margin-top: 10px!important"
          placeholder="От"
          class="inline-block"
        />
        <b-form-input
          v-model.number="groupState.age_to"
          type="number"
          no-wheel
          style="margin-top: 10px!important"
          placeholder="До"
          class="inline-block"
        />
      </div>
      <div class="mt-2">
        <div>Считать возраст участников на дату:</div>
        <flat-pickr
          v-model="groupState.age_reference_date"
          class="form-control"
          :config="config"
        />
      </div>
      <div
        :key="'group.groupType' + groupIndex"
        style="margin-top: 20px"
      >
        Формат группы
      </div>
      <v-select
        v-model="groupState.format"
        label="name"
        :options="sexArrow"
        :reduce="option => option.value"
        @input="selectGroupFormat"
      />
      <div
        v-if="groupState.format === 'Combined'"
        :key="'group.stages_' + groupIndex"
      >
        <stage
          v-for="(stage, index) in stages"
          :key="'group_' + groupIndex + 'stage' + index"
          :combination_dict="group.combination_dict"
          :stage="stage"
          :stage-index="index"
          :group-type="groupState.format === 'Combined'"
        />
      </div>
      <div
        v-else-if="groupState.format === 'Minimum'"
        class="inline-block mt-2"
      >
        <b-form-group
          label="Пол"
          class="checkbox-container"
        >
          <b-form-radio-group
            v-model="group.min_sex"
            :options="optionsRadio"
            :name="`radio-inline-${groupIndex}`"
          />
        </b-form-group>
        <b-form-group
          label="Минимальное количество"
          class="mt-1"
        >
          <b-form-input
            id="group-age-to"
            v-model.number="group.min_number"
            type="number"
            no-wheel
            placeholder="Минимальное кол-во человек"
            class="inline-block"
          />
        </b-form-group>
      </div>
    </template>
  </div>
</template>

<script>
import {
  BFormInput, BButton, BTooltip, BFormRadioGroup, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import Stage from './Stage.vue'

export default {
  name: 'Group',
  components: {
    BFormInput,
    BFormRadioGroup,
    BButton,
    BTooltip,
    BFormGroup,
    Stage,
    vSelect,
    flatPickr,
  },
  props: ['group', 'groupIndex', 'isRestricted', 'distanceFormat', 'stages'],
  data() {
    return {
      groupState: this.group,
      sexArrow: [
        { name: 'Мужской', value: 'Male' },
        { name: 'Женский', value: 'Female' },
        { name: 'Любой', value: 'Any' },
      ],
      config: {
        allowInput: true,
        altFormat: 'd.m.Y H:i',
        altInput: true,
        enableTime: true,
        dateFormat: 'Z',
        locale: Russian, // locale for this instance only
      },
      optionsRadio: [
        { text: 'М', value: 'Male' },
        { text: 'Ж', value: 'Female' },
      ],
    }
  },
  watch: {
    group: {
      handler() {
        this.groupState = this.group
      },
      immediate: true,
      deep: true,
    },
    groupState: {
      handler(newValue, prev) {
        this.$emit('update-group', this.groupState, this.groupIndex)
      },
      deep: true,
    },
    distanceFormat: {
      handler(newValue, prev) {
        if (this.distanceFormat === 'Эстафета') {
          this.sexArrow.push({ name: 'Смешанный', value: 'Combined' })
          this.sexArrow.push({ name: 'По минимальному кол-ву', value: 'Minimum' })
        }
      },
      immediate: true,
    },
  },
  methods: {
    deleteGroup() {
      this.$emit('delete-group', this.groupState, this.groupIndex)
    },
    selectGroupFormat(value) {
      if (value === 'Minimum') {
        this.groupState.min_sex = 'Male'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.input-container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}

.checkbox-container {
  display: flex;
}
</style>
