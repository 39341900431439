<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Общая информация
        </h4>
      </div>
      <div class="card-body">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Название новости"
              >
                <b-form-input
                  v-model="oneNews.title"
                  placeholder="Название новости"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Категория
        </h4>
      </div>
      <span class="bv-no-focus-ring col-form-label pt-0 categoryLabel">
        Выберите категорию
      </span>
      <v-select
        v-model="selectedCategory"
        :close-on-select="false"
        :options="categories"
        :reduce="category => category"
        label="name"
        input-id="name"
        class="select"
        :value="selectedCategory"
      />
    </div>

    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Теги
        </h4>
      </div>
      <div
        v-if="hashtags.length > 0"
        class="tagWrap"
      >
        <div
          v-for="(item, index) in hashtags"
          :key="item + index"
        >
          <span>{{ item.name }}</span>
          <x-icon
            stroke="black"
            size="1x"
            class="cursor"
            @click="deleteTag(item.name)"
          />
        </div>
      </div>
      <div class="card-body">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Название тега"
              >
                <div class="flex-row">
                  <vue-autosuggest
                    v-model="tag"
                    class="autosuggest-input"
                    :suggestions="filteredTags"
                    :input-props="{class: 'form-control'}"
                    placeholder="Введите тег"
                    @input="searchTags"
                    @keyup.enter="submit"
                    @selected="selected"
                  >
                    <div slot-scope="{suggestion}">
                      <span
                        v-if="filteredTags"
                        class="my-suggestion-item cursor"
                      >{{ suggestion.item.name }}</span>
                    </div>
                  </vue-autosuggest>

                  <plus-icon
                    size="1.5x"
                    class="cursor"
                    @click="submit"
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <b-form @submit.prevent>
          <b-row>
            <b-col
              v-if="oneNews.main_image"
              md="6"
            >
              <h4 class="card-title">
                Обложка
              </h4>
              <div class="images-container mb-3">
                <div
                  class="image-container"
                >
                  <img
                    :src="oneNews.main_image.fullPath"
                    alt=""
                  >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="delete-image-button"
                    @click="deleteMainImage"
                  >
                    Удалить
                  </b-button>
                </div>
              </div>
            </b-col>
            <b-col
              v-if="oneNews.images.length"
              md="6"
            >
              <h4 class="card-title">
                Дополнительные фото
              </h4>
              <div class="images-container mb-3">
                <div
                  v-for="(image, index) in oneNews.images"
                  :key="image.id"
                  class="image-container"
                >
                  <img
                    :src="image.fullPath"
                    alt=""
                  >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="delete-image-button"
                    @click="oneNews.images.splice(index, 1)"
                  >
                    Удалить
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <div class="upload-wrap">
                <file-uploader
                  title="Обложка новости"
                  hide-control-buttons
                  @updatePhotos="addMainImage"
                />
              </div>
            </b-col>
            <b-col md="6">
              <div class="upload-wrap">
                <file-uploader
                  title="Доп. изображение"
                  hide-control-buttons
                  @updatePhotos="addImage"
                />
              </div>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>

    <div class="card">
      <div class="card-header" />
      <div class="card-body">
        <h4 class="card-title">
          Описание
        </h4>
        <div class="my-1">
          Полное описание
        </div>
        <quill-editor
          v-model="oneNews.full_description"
        />
        <div class="my-1 mt-5">
          Краткое описание
        </div>
        <quill-editor
          v-model="oneNews.short_description"
        />
      </div>
    </div>

    <b-col>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="mr-1 save-post-button"
        :disabled="isloading"
        @click="saveItem"
      >
        <b-spinner
          v-if="isloading"
          small
        />
        {{ isloading ? 'Сохранение...' : 'Сохранить' }}
      </b-button>
    </b-col>
  </div>
</template>

<script>
import { BButton, BCol, BForm, BFormGroup, BFormInput, BRow, BSpinner, VBPopover } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueAutosuggest } from 'vue-autosuggest'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import { PlusIcon, XIcon } from 'vue-feather-icons'
import FileUploader from '@/global-components/FileUploader'
import crudModule from '../../libs/crud_module'

const oneNewsModule = crudModule({
  entity_name: 'news',
})
const categoriesModule = crudModule({
  entity_name: 'news-category',
})
const tagsModule = crudModule({
  entity_name: 'hash-tag/news',
})

export default {
  name: 'OneNewsPage',
  components: {
    PlusIcon,
    XIcon,
    FileUploader,
    vSelect,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BSpinner,
    VueAutosuggest,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      config: {
        allowInput: true,
        altFormat: 'd.m.Y H:i',
        altInput: true,
        enableTime: true,
        dateFormat: 'Z',
        locale: Russian, // locale for this instance only
      },
      isloading: false,
      oneNews: {
        images: [],
        main_image: null,
      },
      tag: '',
      categories: [],
      selectedCategory: {},
      hashtags: [],
      filteredTags: [],
      debounceTimeout: null,
    }
  },
  watch: {
    selectedCategory() {
      this.oneNews.categoryId = this.selectedCategory.id
    },
    hashtags() {
      this.oneNews.hashtags = this.hashtags
    },
  },
  async created() {
    if (this.$route.params.id === 'new') {
      const response = await categoriesModule.fetchItems()
      this.categories = response.data.rows
    } else {
      try {
        await oneNewsModule.fetchItem(this.$route.params.id, {
          query: {
            admin: '1',
          },
        }).then(result => {
          this.oneNews = {
            ...result.data,
            images: result.data.images.length !== 0 ? result.data.images : [],
          }
          this.hashtags = result.data.hashtags
        })

        await categoriesModule.fetchItems().then(response => {
          this.categories = response.data.rows
          this.selectedCategory = this.categories.find(item => {
            if (item.id === this.oneNews.categoryId) {
              return item
            }
          })
        })
      } catch (e) {
        console.error(e)
      }
    }
  },
  methods: {
    deleteTag(selectedTagName) {
      this.hashtags = this.hashtags.filter(item => item.name !== selectedTagName)
    },
    submit(e) {
      e.target.value = ''
      this.hashtags.push({ name: this.tag })
      this.tag = ''
    },
    fetchTags() {
      if (this.tag) {
        tagsModule.fetchItems({ filterText: this.tag }).then(res => {
          this.filteredTags = [{ data: res.data }]
        })
      } else {
        this.filteredTags = []
      }
    },
    searchTags() {
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(this.fetchTags, 300)
    },
    selected(item) {
      this.tag = item.item.name
    },
    saveItem() {
      if (this.oneNews.images.length) {
        this.oneNews.images = this.oneNews.images.map(img => img.id)
      }

      if (this.$route.params.id === 'new') {
        this.createNews()
      } else {
        this.updateNews()
      }
    },
    addImage(image) {
      this.oneNews.images.push(image)
    },
    addMainImage(image) {
      this.oneNews.mainImageId = image.id
      this.oneNews.main_image = image
      console.log(this.oneNews)
    },
    deleteMainImage() {
      this.oneNews.mainImageId = null
      this.oneNews.main_image = null
    },
    async updateNews() {
      try {
        await oneNewsModule.updateItem(this.oneNews.id, this.oneNews)
          .then(response => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Новость сохранена',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'news' })
            return response
          })
          .catch(error => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      } catch (e) {
        this.isloading = false
        console.error(e)
      }
    },
    async createNews() {
      try {
        await oneNewsModule.createItem(this.oneNews)
          .then(response => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Новость добавлена',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'news' })
            return response
          })
          .catch(error => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      } catch (e) {
        this.isloading = false
        console.error(e)
      }
    },
  },
}
</script>

<style lang="scss">
  .upload-wrap{
    margin-bottom: 20px;
  }
  .save-post-button{
    position: fixed !important;
    bottom: 30px;
    right: 15px;
    z-index: 1000;
  }
  .tagWrap {
    margin: 20px 20px 0;
    display: flex;
    gap: 10px;
  }
  .select {
    padding: 0 21px 21px;
    width: 100%;
  }
  .cursor {
    cursor: pointer
  }
  .categoryLabel {
    padding-left: 21px;
  }
  .flex-row {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  .autosuggest-input {
    width: 100%;
    position: relative;
  }
  .autosuggest__results-container {
    position: absolute;
    z-index: 1000;
    width: 100%;
    background-color: #fff;
  }
  .autosuggest__results {
    box-shadow: 4px 4px 24px 4px rgba(34, 41, 47, 0.1);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .autosuggest__results ul {
    list-style-type: none;
  }
  .dark-layout .autosuggest__results-container {
    background-color: #283046;
  }
  .my-suggestion-item {
    display: flex;
    justify-content: start;
    padding: 3px;
  }

.autosuggest__results-item--highlighted {
  background-color: #7367f0;
  color: #fff;
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss" scoped>
.images-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  .image-container {
    max-width: 200px;
    min-width: 150px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);

    img {
      height: 180px;
      border-radius: 10px;
    }

    .delete-image-button {
      width: min-content;
      align-self: center;
    }
  }
}
</style>
