<template>
  <b-modal
    id="modal-confirm"
    ok-title="Удалить"
    modal-class="modal-primary"
    centered
    title="Уведомление об удалении"
    cancel-title="Отмена"
    @ok="confirm"
    @cancel="close"
  >
    <div>{{ message }}</div>
  </b-modal>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue'
import ConfirmToDeleteService from '../libs/confirmToDeleteService'

export default {
  name: 'ConfirmModal',
  components: [
    BModal, BButton,
  ],
  data() {
    return {
      service: ConfirmToDeleteService,
    }
  },
  computed: {
    message() {
      return this.service.message
    },
  },
  watch: {
    'service.isVisible': {
      handler(newValue) {
        if (newValue) {
          this.$bvModal.show('modal-confirm')
        } else {
          this.$bvModal.hide('modal-confirm')
        }
      },
    },
  },
  methods: {
    confirm() {
      this.service.confirm()
    },
    close() {
      this.service.close()
    },
  },
}
</script>
