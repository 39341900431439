class ImageUpload {
  constructor(quill, options) {
    this.quill = quill
    this.options = options
    this.toolbar = quill.getModule('toolbar')

    if (this.toolbar) {
      this.toolbar.addHandler('image', this.selectLocalImage.bind(this))
    }
  }

  selectLocalImage() {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    input.onchange = () => {
      const file = input.files[0]
      if (file) {
        this.uploadImage(file)
      }
    }
  }

  uploadImage(file) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('lastModified', file.lastModified)

    fetch(this.options.uploadUrl, {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        if (data && data.fullPath) {
          this.insertToEditor(data.fullPath)
        } else {
          console.error('Error uploading image')
        }
      })
      .catch(err => {
        console.error('Error uploading image:', err)
      })
  }

  insertToEditor(url) {
    const range = this.quill.getSelection()
    this.quill.insertEmbed(range.index, 'image', url)
  }
}

export default ImageUpload
