<template>
  <div class="mt-2">
    <div class="mb-1">
      {{ title }}
    </div>
    <b-form-input
      id="input-tooltip"
      v-model.number="discountValue"
      type="number"
      no-wheel
      placeholder="Скидка в %"
      class="inline-block"
      :disabled="isRestricted"
      @input="updateValue"
    />
    <b-tooltip
      v-if="isRestricted"
      target="input-tooltip"
      triggers="hover"
    >
      {{ tooltipText }}
    </b-tooltip>
    <div class="switch">
      <div class="mt-1 mb-1">
        Скидка в %
      </div>
      <div
        id="sale-checkbox-tooltip"
        class="d-inline-block"
        tabindex="0"
      >
        <b-form-checkbox
          v-model="discountPercent"
          class="custom-control-primary"
          name="check-reg"
          :disabled="isRestricted"
          style="margin-bottom: 10px"
          switch
          @change="changeDiscountPercentType"
        />
      </div>
      <b-tooltip
        v-if="isRestricted"
        target="sale-checkbox-tooltip"
        triggers="hover"
      >
        {{ tooltipText }}
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import { BFormInput, BTooltip, BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'DiscountSwitcher',
  components: { BFormInput, BTooltip, BFormCheckbox },
  props: {
    title: {
      type: String,
      default: '',
    },
    tooltipText: {
      type: String,
      default: 'В данной дистанции есть регистрации',
    },
    isRestricted: {
      type: Boolean,
      default: false,
    },
    discountIsPercent: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      discountPercent: this.discountIsPercent,
      discountValue: null,
    }
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue && !this.discountPercent) {
          this.discountValue = +newValue / 100
        } else {
          this.discountValue = +newValue
        }
      },
      immediate: true,
    },
    discountPercent: {
      handler(newValue, oldValue) {
        const discount = Number(this.discountValue)
        this.$emit('input', oldValue === false ? discount : discount * 100)
      },
    },
  },
  methods: {
    changeDiscountPercentType(value) {
      this.discountPercent = value
      this.$emit('discountType', value)
    },
    updateValue(value) {
      const discount = Number(value)
      if (this.discountPercent) {
        this.$emit('input', discount)
      } else {
        this.$emit('input', discount * 100)
      }
    },
  },
}
</script>
