export default {
  bind(el, binding, vnode) {

    let modifier = null
    let variableName = binding.expression

    if (typeof binding.value === 'object' && binding.value.value && binding.value.modifier) {
      modifier = binding.value.modifier
      variableName = extractValue(binding.expression)

      if (!variableName) {
        throw new Error(`Expected ${binding.expression} to be a variable name or an object with value-modifier structure`)
      }

      el.value = modifier(binding.value.value)
    } else {
      el.value = binding.value
    }

    const updateValue = (value) => {

      if (modifier) {
        vnode.context[variableName] = modifier(value)
        return
      }
      vnode.context[variableName] = value
    }

    const directiveName = binding.arg || 'input'
    vnode.componentInstance.$on(directiveName, updateValue)

    el._removeModelCustomHandler = () => {
      vnode.componentInstance.$off(directiveName, updateValue)
    }
  },

  update(el, binding) {
    el.value = binding.value
  },

  unbind(el) {
    if (el._removeModelCustomHandler) {
      el._removeModelCustomHandler()
      delete el._removeModelCustomHandler
    }
  },
}

function extractValue(inputString) {
  const regex = /value:\s*([a-zA-Z_$][0-9a-zA-Z_$]*)/
  const match = inputString.match(regex)
  return match ? match[1] : null
}