<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Общая информация
        </h4>
      </div>
      <div class="card-body">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Имя"
              >
                <b-form-input
                  v-model="user.name"
                  placeholder="Имя"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>

        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Фамилия"
              >
                <b-form-input
                  v-model="user.surname"
                  placeholder="Фамилия"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>

        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Пол"
              >
                <b-form-input
                  v-model="user.sex"
                  placeholder="Пол"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>

        <div style="width: 97.5%; margin-bottom: 15px">
          <label
            class="font-size"
            for="datepicker"
          >Дата рождения</label>
          <b-form-datepicker
            id="datepicker"
            v-model="user.birthday"
            placeholder="YYYY-MM-DD"
            locale="ru"
            class="col-md-6"
          />
        </div>

        <file-uploader v-model="user.photo_id"
                       :default="user.photo"
                       title="Фото профиля"/>

      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Данные для регистрации
        </h4>
      </div>
      <div class="card-body">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Номер телефона"
              >
                <b-form-input
                  v-model="user.number"
                  placeholder="Номер телефона"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-checkbox
                      v-model="user.verified"
                      plain
              >
                Номер телефона подтверждён
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-form>

        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Email"
              >
                <b-form-input
                  v-model="user.email"
                  placeholder="Email"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>

        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Password"
              >
                <b-form-input
                  v-model="user.password"
                  placeholder="Password"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Ссылки
        </h4>
      </div>
      <div class="card-body">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Instagram"
              >
                <b-form-input
                  v-model="user.instagram"
                  placeholder="Instagram"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Whatsapp"
              >
                <b-form-input
                  v-model="user.whatsapp"
                  placeholder="Whatsapp"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="VK"
              >
                <b-form-input
                  v-model="user.vk"
                  placeholder="VK"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Twitter"
              >
                <b-form-input
                  v-model="user.twitter"
                  placeholder="Twitter"
                />
              </b-form-group>
            </b-col>

          </b-row>
        </b-form>
      </div>
    </div>

    <b-col>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="mr-1 save-button"
        :disabled="isloading"
        @click="saveItem"
      >
        <b-spinner
          v-if="isloading"
          small
        />
        {{ isloading ? 'Сохранение...' : 'Сохранить' }}
      </b-button>
    </b-col>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
  VBPopover,
  BFormDatepicker,
  BFormCheckbox
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import crud_module from '../../libs/crud_module'
import FileUploader from "../../global-components/FileUploader";

const user_module = crud_module({
  entity_name: 'user',
})

const changePassword = crud_module({
  entity_name: 'authentication/temporary-password',
})

export default {

  name: 'CategoryPage',
  components: {
    FileUploader,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BSpinner,
    BFormDatepicker,
    BFormCheckbox,
    ToastificationContent
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      config: {
        allowInput: true,
        altFormat: 'd.m.Y H:i',
        altInput: true,
        enableTime: true,
        dateFormat: 'Z',
        locale: Russian, // locale for this instance only
      },
      isloading: false,
      downloadDisabled: false,
      user: {},
      textError: '',
    }
  },
  watch: {

  },
  async created() {
    if (this.$route.params.id === 'new') {
      return
    }
    try {
      await user_module.fetchItem(this.$route.params.id).then(result => this.user = result.data)
      this.user.verified = !!this.user.verified

      console.log(this.user)
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    saveItem() {
      this.user.verified = this.user.verified ? 1 : 0
      if (this.$route.params.id === 'new') {
        this.createUser()
      } else {
        if (this.user.password) {
          this.updateUserPassword()
        }
        this.updateUser()
      }
    },
    async updateUser() {
      try {
        await user_module.updateItem(this.user.id, this.user)
          .then(response => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Пользователь сохранен',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'users' })
            return response
          })
          .catch(error => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      } catch (e) {
        this.isloading = false
        console.error(e)
      }
    },
    async createUser() {
      try {
        await user_module.createItem(this.user)
          .then(response => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Пользователь добавлен',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'users' })
            return response
          })
          .catch(error => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      } catch (e) {
        this.isloading = false
        console.error(e)
      }
    },
    async updateUserPassword() {
      try {
        const payload = {
          password: this.user.password,
          newPassword: this.user.password,
        }
        console.log(payload)
        await changePassword.updateItem(this.user.id, payload)
          .then(response => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Пороль изменен',
                variant: 'success',
              },
            })
            return response
          })
          .catch(error => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      } catch (e) {
        this.isloading = false
        console.error(e)
      }
    },
  },
}
</script>
<style scoped lang="scss">
  .save-button{
    position: fixed!important;
    bottom: 30px;
    right: 15px;
    z-index: 1000;
  }
  .font-size {
    font-size: inherit;
  }
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
