<template>
  <b-modal
    id="modal-promo"
    ref="promo-modal"
    title="Шаблоны промокодов"
    hide-footer
    no-close-on-backdrop
  >
    <v-select
      v-model="selected"
      label="name"
      :options="promosTemplates"
    />
    <b-button
      variant="primary"
      class="mr-1 mt-2"
      :disabled="!selected"
      @click="updatePromo"
    >
      Сохранить
    </b-button>
  </b-modal>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import Ripple from 'vue-ripple-directive'
import crudModule from '@/libs/crud_module'

const promosModule = crudModule({
  entity_name: 'promocode-template',
})
export default {
  name: 'AddPromosTemplatesModal',
  components: {
    vSelect,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      promosTemplates: [],
      selected: null,
    }
  },
  created() {
    this.getTemplates()
  },
  methods: {
    async getTemplates() {
      this.promosTemplates = await promosModule.fetchItems().then(r => r.data.rows)
    },
    updatePromo() {
      this.$emit('select', this.selected.instances)
      this.selected = null
      this.$refs['promo-modal'].hide()
    },
  },
}
</script>
