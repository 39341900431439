<template>
  <div class="template-discount">
    <b-button
      v-b-modal.modal-discount
      variant="primary"
      class="mr-1"
      @click="template = {
        name: '',
        discounts: []
      }"
    >
      Добавить шаблон
    </b-button>
    <div class="wrapper">
      <div
        v-for="(promo, i) in discounts"
        :key="'all-groups_' + i"
        style="margin-top: 20px; margin-bottom: 0!important;"
        class="card"
      >
        <div class="card-header">
          <div>
            <h4 class="card-title">
              {{ promo.name }}
            </h4>
          </div>
        </div>
        <div class="card-body">
          <b-button
            v-b-modal.modal-discount
            variant="primary"
            class="mr-1"
            @click="getTemplate(promo.id)"
          >
            Редактировать шаблон
          </b-button>
          <b-button
            variant="primary"
            class="mr-1"
            @click="deleteTemplate(promo.id)"
          >
            Удалить шаблон
          </b-button>
        </div>
      </div>
    </div>
    <infinite-loading
      spinner="bubbles"
      :identifier="infiniteTrigger"
      @infinite="infiniteScroll"
    />
    <discount-template-modal
      :template="template"
      @update="getTemplates"
    />
  </div>
</template>

<script>
import {
  VBPopover, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import InfiniteLoading from 'vue-infinite-loading'
import DiscountTemplateModal from './DiscountTemplateModal.vue'
import crudModule from '@/libs/crud_module'
import ConfirmToDeleteService from '@/libs/confirmToDeleteService'

const discountModule = crudModule({
  entity_name: 'personal-discount-template',
})

export default {
  name: 'DiscountsPage',
  components: {
    InfiniteLoading,
    BButton,
    DiscountTemplateModal,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      template: {
        name: '',
        discounts: [],
      },
      discounts: [],
      skipCountTemplates: 0,
      maxResultCountTemplates: 10,
      infiniteTrigger: 0,
    }
  },
  methods: {
    async deleteTemplate(id) {
      ConfirmToDeleteService.open('Вы уверены, что хотите удалить этот шаблон?', async () => {
        await discountModule.deleteItem(id)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Шаблон удален',
                variant: 'success',
              },
            })
          }).catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'success',
              },
            })
          }).finally(() => {
            this.getTemplates()
          })
      })
    },
    async getTemplate(id) {
      const template = await discountModule.fetchItem(id).then(r => r.data)
      this.template.id = template.id
      this.template.name = template.name
      this.template.discounts = template.instances
    },
    async getTemplates() {
      this.discounts = []
      this.skipCountTemplates = 0
      this.infiniteTrigger += 1
    },
    async infiniteScroll($state) {
      await discountModule.fetchItems({
        skipCount: this.skipCountTemplates,
        maxResultCount: this.maxResultCountTemplates,
      }).then(r => {
        if (r.data.rows.length > 0) {
          this.discounts.push(...r.data.rows)
          this.skipCountTemplates += this.maxResultCountTemplates
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
    .wrapper{
      display: flex;
      margin: 0 -15px;
      flex-wrap: wrap;
      .card {
        width: calc(33.33% - 30px);
        margin: 15px;
        @media (max-width: 1500px){
        width: calc(50% - 30px);
        }
        @media (max-width: 767px){
        width: 100%;
        }
      }
      .card-body{
        display: flex;
      }
    }
    .group-item {
      font-size: 16px;
      font-weight: 500;
    }

    .checkbox-container {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .time-pick {
      position: relative;
    }
    </style>
    <style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';

    .vs__open-indicator {
      fill: #7367f0;
    }
</style>
