<template>
  <div class="my-wrapper">
    <table
      v-if="list.length"
      class="table table-striped"
    >
      <thead class="thead-dark">
        <tr>
          <th scope="col">
            Номер
          </th>
          <th scope="col">
            Название
          </th>
        </tr>
      </thead>
      <draggable
        :key="list.length"
        v-model="list"
        tag="tbody"
      >
        <tr
          v-for="item, index in list"
          :key="item.id"
        >
          <td scope="row">
            {{ 1 + index }}
          </td>
          <td v-if="item.type">
            {{ item.type }}
          </td>
          <td v-else-if="item.name && item.surname">
            {{ item.name + ' ' + item.surname }}
          </td>
          <td v-else-if="item.name">
            {{ item.name }}
          </td>
          <td v-else-if="item.question">
            {{ item.question }}
          </td>
        </tr>

      </draggable>
    </table>
    <div
      v-else
      class="empty"
    >
      Нет элементов для выбора
    </div>
    <b-button
      variant="primary"
      class="mr-1"
      @click="select"
    >
      Принять
    </b-button>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    draggable,
    BButton,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      list: [],
    }
  },
  watch: {
    items(newVal) {
      if (newVal) {
        this.sortedList([...this.items])
      }
    },
  },
  mounted() {
    this.sortedList([...this.items])
  },

  beforeDestroy() {
    this.list = []
  },
  methods: {
    select() {
      const orderArray = this.list.map(e => e.id)
      this.$emit('select', orderArray)
    },
    sortedList(value) {
      if (value.length) {
        const res = value.sort((a, b) => a.order - b.order)
        this.list = res
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.my-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.empty {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  width: 100%;
}
</style>
