<template>
  <div class="card">
    <div class="card-body">
      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <div class="mr-auto">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="$router.push({name: 'subscription-page', params: {id: 'new'}})"
          >
            Новый абонемент
          </b-button>
          <b-button
            v-b-modal.modalQueue
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            @click="handleOpenQueueModal"
          >
            Порядок Абонементов
          </b-button>
        </div>
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Поиск</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
              @change="onSearch"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Status -->
          <span v-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">

            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <router-link
                  :to="{name: 'subscription-page', params:{ id: props.row.id }}"
                  class="edit-button"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Редактировать</span>
                </router-link>

                <b-dropdown-item
                  v-b-modal.modal-primary
                  variant="outline-primary"
                  @click="clubToDelete=props.row.id"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Удалить</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
      <infinite-loading
        ref="infloader"
        spinner="bubbles"
        @infinite="infiniteScroll"
      >
        <div slot="no-more" />
        <div slot="no-results" />
      </infinite-loading>
    </div>
    <div>
      <!-- button trigger -->
      <div class="demo-inline-spacing" />

      <!-- modal -->
      <b-modal
        id="modal-primary"
        v-model="modalIsOpen"
        ok-only
        ok-title="Отмена"
        modal-class="modal-primary"
        centered
        title="Primary Modal"
      >
        <div>Вы действительно хотите удалить абонемент?</div>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="mt-1"
          variant="primary"
          @click="deleteItem(clubToDelete)"
        >
          Удалить
        </b-button>
      </b-modal>

      <b-modal
        id="modalQueue"
        v-model="isQueueOpen"
        no-close-on-backdrop
        hide-footer
        modal-class="modal-primary"
        centered
        title="Порядок тренировок"
      >
        <label
          for="kindOfSport"
          class="my-label"
        >Вид спорта</label>
        <v-select
          v-model="kindOfSport"
          :close-on-select="true"
          :options="kindOfSports"
          :reduce="item => item.id"
          :get-option-label="item => item.name"
          input-id="name"
          :searchable="false"
          class="select mb-1 pb-0"
        />
        <DragDropTable
          :items="filteredRows"
          @select="handleSelectQueue"
        />
      </b-modal>

    </div>
  </div>
</template>

<script>
import {
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import InfiniteLoading from 'vue-infinite-loading'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import crud_module from '../../../libs/crud_module'
import DragDropTable from '../DragDropTable.vue'

const subscriptions_module = crud_module({
  entity_name: 'subscription',
})

const kindsofsports_module = crud_module({
  entity_name: 'kind-of-sport',
})

export default {
  components: {
    VueGoodTable,
    BBadge,
    DragDropTable,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    vSelect,
    BButton,
    InfiniteLoading,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      skipCount: 0,
      maxResultCount: 9,
      modalIsOpen: false,
      clubToDelete: null,
      isQueueOpen: false,
      kindOfSport: null,
      columns: [
        {
          label: 'Название',
          field: 'name',
        },
        {
          label: 'Вид спорта',
          field: 'kindOfSport.name',
        },
        {
          label: 'Дата',
          field: this.formatDate,
        },
        {
          label: 'Действие',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      kindOfSports: [],
      status: [
        {
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    filteredRows() {
      return this.rows.filter(e => e.kindOfSportId === this.kindOfSport)
    },
  },
  async created() {
    try {
      this.kindOfSports = await kindsofsports_module
        .fetchItems()
        .then(res => res.data.rows)
      this.kindOfSport = this.kindOfSports[0].id
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    async onSearch() {
      this.rows = []
      await this.fetchItems({
        filterText: this.searchTerm,
        maxResultCount: this.maxResultCount,
      })
    },
    async infiniteScroll($state) {
      const payload = {
        filterText: this.searchTerm,
        skipCount: this.skipCount,
        maxResultCount: this.maxResultCount,
      }
      await this.fetchItems(payload, $state)
    },
    async fetchItems(payload, $state) {
      await subscriptions_module.fetchItems(payload)
        .then(res => {
          if (res.data.rows.length > 0) {
            this.rows.push(...res.data.rows)
            this.skipCount += this.maxResultCount
            $state.loaded()
          } else {
            $state.complete()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    async deleteItem(id) {
      const payload = {
        skipCount: '',
        maxResultCount: this.maxResultCount,
      }
      await subscriptions_module.deleteItem(id)
      await subscriptions_module.fetchItems(payload).then(response => {
        this.rows = response.data.rows
      })
      this.modalIsOpen = false
    },
    handleOpenQueueModal() {
      this.isQueueOpen = true
    },
    async handleSelectQueue(value) {
      const payload = { order: value }
      await subscriptions_module.updateOrder(payload).then(r => {
        if (r.data.success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Уведомление',
              icon: 'BellIcon',
              text: 'Порядок Сохранен',
              variant: 'success',
            },
          })
          this.updateData()
        }
      })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Уведомление',
              icon: 'BellIcon',
              text: error.response.data.message,
              variant: 'danger',
            },
          })
        })
      this.isQueueOpen = false
    },
    async updateData() {
      this.rows = []
      this.skipCount = 0
      this.$refs.infloader.stateChanger.reset()
    },
    formatDate(rowObj) {
      const newDate = new Date(rowObj.createdAt)
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      const newStr = newDate.toLocaleDateString('ru', options)
      return newStr
    },
  },
}
</script>

<style lang="scss">
.my-label {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  width: 100%;
}

  .edit-button{
    padding: 0.65rem 1.28rem;
    display: flex;
    align-items: center;

    &:hover{
      background-color: rgba(115, 103, 240, 0.12);
    }
  }
  @import '@core/scss/vue/libs/vue-good-table.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
