<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Общая информация
        </h4>
      </div>
      <div class="card-body">
        <span>Дата создания альбома: {{ formatDate(date) }}</span>
      </div>
      <div class="card-body">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Название старта"
              >
                <v-select
                  v-model="startOption"
                  :placeholder="'Введите название старта'"
                  :close-on-select="true"
                  :options="startOptions"
                  :reduce="startOption => startOption"
                  label="label"
                  input-id="short_name"
                  :value="startOption"
                  class="select"
                  @search="(search, loading) => getStarts(search, loading)"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>

      <div class="card-body">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Название альбома"
              >
                <div class="flex-row">
                  <b-form-input
                    v-model="albumName"
                    placeholder="Введите название альбома"
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>

      <div class="card-body">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <div class="flex-row">
                <b-form-checkbox
                  v-model="isBeforeStart"
                  type="checkbox"
                />
                <span>Альбом будет показан до начала старта</span>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Фотографии
        </h4>
      </div>
      <div class="card-body">
        <b-col
          md="6"
          style="padding-left: 0;"
        >
          <div class="upload-wrap">
            <span>Загрузить фото</span>
            <multi-file-uploader
              v-model="newFiles"
              @updatePhotos="getPhotos"
              @checkNewPhotos="checkNewPhotos"
            />
          </div>
        </b-col>
        <vue-selecto
          ref="selecto"
          drag-container=".images-container"
          :selectable-targets="[&quot;.selecto-area .card__wrapper&quot;]"
          :hit-rate="20"
          :select-by-click="true"
          :scroll-options="scrollOptions"
          :ratio="0"
          @dragStart="onDragStart"
          @select="onSelect"
          @scroll="onScroll"
        />
        <div
          id="selecto1"
          ref="scroller"
          :class="['images-container', 'scroll', 'selecto-area', photos.length ? '' : 'hidden-container']"
          @scroll="onScrollerScroll"
        >
          <div
            v-for="(photo, index) in photos"
            :key="index"
            style="display: flex; flex-direction: column;"
            class="photo-container"
          >
            <photo-item
              :album="album"
              :photo="photo"
              :hashtags="hashtags"
              @setSelectedPhoto="setSelectedPhoto"
              @deletePhoto="deletePhoto"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Теги для выбранных фотографий
        </h4>
      </div>
      <div
        v-if="hashtags.length > 0"
        class="tagWrap"
      >
        <div
          v-for="(item, index) in hashtags"
          :key="item + index"
        >
          <span>{{ item.name }}</span>
        </div>
      </div>
      <div class="card-body">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Название тега"
              >
                <div class="flex-row">
                  <b-form-input
                    v-model="tag"
                    placeholder="Введите тег для выбранных фотографий"
                    :disabled="checkedPhotos.length === 0 && !filesBeforeUpload"
                    @keyup.enter="submit"
                  />
                  <plus-icon
                    size="1.5x"
                    class="cursor"
                    @click="submit"
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>

    <b-col>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="mr-1 save-button"
        :disabled="isloading"
        @click="saveItem"
      >
        <b-spinner
          v-if="isloading"
          small
        />
        {{ isloading ? 'Сохранение...' : 'Сохранить' }}
      </b-button>
    </b-col>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
  VBPopover,
  BFormCheckbox,
} from 'bootstrap-vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { PlusIcon } from 'vue-feather-icons'
import vSelect from 'vue-select'
import { VueSelecto } from 'vue-selecto'
import MultiFileUploader from '@/global-components/MultiFileUploader'
import crudModule from '../../../libs/crud_module'
import PhotoItem from './Photo.vue'

const albumModule = crudModule({
  entity_name: 'album',
})
const startModule = crudModule({
  entity_name: 'start',
})
const albumPhotoModule = crudModule({
  entity_name: 'album/photo',
})

export default {
  name: 'AlbumPage',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BSpinner,
    BFormCheckbox,
    ToastificationContent,
    PlusIcon,
    MultiFileUploader,
    vSelect,
    VueSelecto,
    PhotoItem,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      isloading: false,
      file: {},
      hashtags: [],
      tag: '',
      newFiles: null,
      photoIds: [],
      date: null,
      startOptions: [],
      startOption: {},
      photos: [],
      album: {},
      checkedPhotos: [],
      albumName: '',
      newPhotos: [],
      newPhotosIds: [],
      isBeforeStart: false,
      scrollOptions: {},
      filesBeforeUpload: false,
      startData: null,
    }
  },
  computed: {
    startName() {
      if (this.album.start) {
        return this.album.start.short_name
      }
      return ''
    },
    fullPath(file) {
      if (file.fullPath) {
        return file.fullPath
      }
      return ''
    },
  },
  watch: {
    newFiles() {
      this.photoIds.push(this.newFiles)
      this.newPhotosIds.push(this.newFiles)
    },
    newPhotos() {
      this.photos = [...this.photos, ...this.newPhotos]
      this.postPhoto()
    },
  },
  mounted() {
    this.scrollOptions = {
      container: this.$refs.scroller,
      throttleTime: 30,
      threshold: 0,
    }
  },
  async created() {
    try {
      await albumModule.fetchItem(this.$route.params.id, {
        query: {
          admin: '1',
        },
      }).then(async result => {
        this.album = result.data
        this.startData = null
        if (!result.data.start.short_name) {
          await startModule.fetchItem(result.data.start_id).then(res => {
            this.startData = res.data
          })
        }
        this.startOption = { ...result.data.start, short_name: this.startData ? this.startData.name : result.data.start.short_name }
        this.startOption.label = `${this.startOption.short_name} (${this.formatDate(this.startOption.start_date)})`
        this.date = result.data.createdAt
        this.photos = result.data.photos
        this.albumName = result.data.name
        this.isBeforeStart = result.data.isBeforeStart
        this.setPhotoIds(result.data.photos)
      })
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    onDragStart(e) {
      if (e.inputEvent.target.nodeName === 'BUTTON') {
        return false
      }
      return true
    },
    onSelect(e) {
      e.added.forEach(el => {
        el.classList.add('active')
        this.setSelectedPhoto(+el.id, [true])
      })
      e.removed.forEach(el => {
        el.classList.remove('active')
        this.setSelectedPhoto(+el.id, [false])
      })
    },
    onScroll(e) {
      this.$refs.scroller.scrollBy(e.direction[0] * 10, e.direction[1] * 10)
    },
    resetScroll() {
      this.$refs.scroller.scrollTo(0, 0)
    },
    onScrollerScroll() {
      this.$refs.selecto.checkScroll()
    },
    setPhotoIds(photos) {
      this.photoIds = photos.map(item => item.file_id)
    },
    setSelectedPhoto(value, checked) {
      this.hashtags = []
      if (checked[0]) {
        this.checkedPhotos.push(this.photos.find(photo => photo.file_id === value))
      } else {
        const index = this.checkedPhotos.findIndex(photo => photo.file_id === value)
        if (index > -1) {
          this.checkedPhotos.splice(index, 1)
        }
      }
    },
    submit(e) {
      this.hashtags = []
      e.target.value = ''
      this.hashtags.push({ name: this.tag })
      if (this.checkedPhotos.length) {
        this.addTagsOnPhotos()
      }
      this.tag = ''
    },
    saveItem() {
      this.updateAlbum()
    },
    async addTagsOnPhotos() {
      const payload = {
        album_id: this.album.id,
        photos: this.checkedPhotos,
        tags: this.hashtags,
      }
      try {
        await albumPhotoModule.updateItems(payload)
          .then(response => {
            this.checkedPhotos.map(photo => {
              const existingTag = photo.tags.find(tag => tag.id === response.data.id)
              if (!existingTag) {
                photo.tags.push(response.data)
              } else {
                return photo
              }
            })
            this.isloading = false
            return response
          })
          .catch(() => {
            this.isloading = false
          })
      } catch (e) {
        this.isloading = false
        console.error(e)
      }
    },
    async updateAlbum() {
      try {
        const payload = {
          start_id: this.startOption.id,
          name: this.albumName,
          isBeforeStart: this.isBeforeStart,
        }

        await albumModule.updateItem(this.album.id, payload)
          .then(response => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Альбом сохранен',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'albums' })
            return response
          })
          .catch(error => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      } catch (e) {
        this.isloading = false
        console.error(e)
      }
    },
    async postPhoto() {
      const postPhotoModule = crudModule({
        entity_name: `album/photo/${this.album.id}`,
      })

      const payload = this.newPhotosIds.map(photoId => {
        if (this.hashtags.length !== 0) {
          return {
            file_id: photoId,
            tags: this.hashtags,
          }
        }
        return {
          file_id: photoId,
        }
      })
      try {
        await postPhotoModule.createItem(payload)
          .then(result => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Альбом сохранен',
                variant: 'success',
              },
            })
            // this.$router.push({ name: 'albums' }
            this.album = result.data
            this.startOption = result.data.start
            this.photos = result.data.photos
            this.albumName = result.data.name
            this.setPhotoIds(result.data.photos)
            this.newPhotosIds = []
            return result
          })
          .catch(error => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      } catch (e) {
        this.isloading = false
        console.error(e)
      }
    },
    debounceSearch(search, loading, fn) {
      clearTimeout(this.debounce)
      if (search.length > 0) {
        this.debounce = setTimeout(fn, 600)
      }
    },
    getStarts(search, loading) {
      this.debounceSearch(search, loading, async () => {
        try {
          await startModule.fetchItems({ filterText: search })
            .then(result => {
              this.startOptions = result.data.rows.map(row => ({ ...row, label: `${row.name} (${this.formatDate(row.start_date)})` }))
              loading(false)
            })
        } catch (e) {
          console.error(e)
        }
      })
    },
    getPhotos(data) {
      const fileForPhoto = {
        file: data,
      }
      this.newPhotos.push(fileForPhoto)
    },
    async deletePhoto(photo) {
      if (photo.id) {
        try {
          await albumPhotoModule.deleteItem(photo.id)
            .then(response => {
              this.isloading = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Уведомление',
                  icon: 'BellIcon',
                  text: 'Фотография удалена',
                  variant: 'success',
                },
              })
              const index = this.checkedPhotos.findIndex(id => id === photo.file.id)
              if (index > -1) {
                this.checkedPhotos.splice(index, 1)
              }
              this.photos = this.photos.filter(item => item.file.id !== photo.file.id)
              this.photoIds = this.photoIds.filter(item => item !== photo.file.id)

              return response
            })
            .catch(error => {
              this.isloading = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Уведомление',
                  icon: 'BellIcon',
                  text: error.response.data.message,
                  variant: 'danger',
                },
              })
            })
        } catch (e) {
          this.isloading = false
          console.error(e)
        }
      } else {
        const index = this.checkedPhotos.findIndex(id => id === photo.file.id)
        if (index > -1) {
          this.checkedPhotos.splice(index, 1)
        }
        console.log('this.checkedPhotos====delete', this.checkedPhotos)
        this.photos = this.photos.filter(item => item.file.id !== photo.file.id)
        this.photoIds = this.photoIds.filter(item => item !== photo.file.id)
      }
    },
    formatDate(date) {
      if (date) {
        const newDate = new Date(date)
        const options = { year: 'numeric', month: 'long', day: 'numeric' }
        const newStr = newDate.toLocaleDateString('ru', options)
        return newStr
      }
      return ''
    },
    checkNewPhotos(data) {
      if (data.length !== 0) {
        this.filesBeforeUpload = true
      } else {
        this.filesBeforeUpload = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .save-button{
    position: fixed!important;
    bottom: 30px;
    right: 15px;
    z-index: 1000;
    margin-top: 20px!important;
  }
  .tagWrap {
    margin: 20px;
    margin-bottom: 0px;
    display: flex;
    gap: 10px;
  }
  .cursor {
    cursor: pointer
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }
  .flex-row {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  .upload-wrap{
    margin-bottom: 20px;
  }

  .container {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }

  .scroll {
    overflow: auto;
    height: 510px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .images-container {
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 40px;
    flex-wrap: wrap;
  }

  .hidden-container {
    display: none;
  }
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
