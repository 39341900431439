<template>
  <div>
    <b-card class="text-center mb-3 p-4 file-drop-area" @dragover.prevent @drop.prevent="handleDrop">
      <p>Перетащите файлы сюда или нажмите для выбора</p>
      <b-button variant="primary" @click="triggerFileInput">
        <b-icon icon="upload" class="mr-1"></b-icon>
        Выбрать файлы
      </b-button>
      <input type="file" multiple @change="handleFiles" ref="fileInput" style="display: none;" />
    </b-card>

    <b-row class="file-list">
      <b-col
        v-for="(file, index) in fileListWithPreview"
        :key="file.id || file.tempId"
        cols="12" md="4" lg="3"
        class="file-item"
        @click="selectFile(file)"
      >
        <div
          :class="['file-preview-wrapper position-relative', { 'selected': isSelected(file) }]"
        >
          <!-- Слой для прелоадера -->
          <div v-if="file.loading || file.deleting" class="loader-overlay">
            <b-spinner label="Loading..." class="loader"></b-spinner>
          </div>

          <!-- Превью или серый прямоугольник -->
          <slot name="file-preview" :file="file">
            <div v-if="!file.fullPath && !file.loading" class="grey-placeholder"></div>
            <img
              v-if="isImage(file) && file.fullPath && !file.loading"
              :src="file.fullPath"
              :alt="file.name"
              class="preview"
            />
            <b-icon
              v-else-if="!isImage(file) && file.fullPath && !file.loading"
              :icon="getFileIcon(file)"
              class="file-icon"
              font-scale="2"
            ></b-icon>
          </slot>

          <!-- Кнопка удаления -->
          <b-icon
            v-if="!file.loading"
            icon="trash-fill"
            variant="danger"
            @click.stop="removeFile(file)"
            class="delete-button text-danger"
          ></b-icon>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios'
import config from '@core/app-config/api-config'
import { BButton, BCard, BRow, BCol, BSpinner, BIcon } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

export default {
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    BSpinner,
    BIcon,
  },
  props: {
    fileList: {
      type: Array,
      default: () => [],
    },
    enableSelection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localFiles: [],
      fileListWithPreview: [],
      selectedFiles: [],
    }
  },
  mounted() {
    this.initializeFileList(this.fileList)
  },
  watch: {
    fileList(newList) {
      this.initializeFileList(newList)
    },
  },
  methods: {
    initializeFileList(files) {
      this.fileListWithPreview = []
      files.forEach((file) => {
        if (!file) return
        const i = this.fileListWithPreview.length
        this.$set(this.fileListWithPreview, i, {
          ...file,
          loading: false,
          deleting: false,
        })
      })
    },
    handleFiles(event) {
      const files = Array.from(event.target.files)
      files.forEach((file) => {
        this.uploadFile(file)
      })
    },
    handleDrop(event) {
      const files = Array.from(event.dataTransfer.files)
      files.forEach((file) => {
        this.uploadFile(file)
      })
    },
    triggerFileInput() {
      this.$refs.fileInput.click()
    },
    uploadFile(file) {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('lastModified', file.lastModified)

      const tempId = Math.random().toString(36).substring(2, 9)
      const fileData = {
        id: null,
        tempId,
        fullPath: URL.createObjectURL(file),
        name: file.name,
        loading: true,
        deleting: false,
      }
      this.fileListWithPreview.push(fileData)

      axios
        .post(`${config.host}/file/upload`, formData)
        .then((response) => {
          const serverFile = response.data
          const fileIndex = this.fileListWithPreview.findIndex((f) => f.tempId === tempId)
          if (fileIndex !== -1) {
            this.$set(this.fileListWithPreview, fileIndex, {
              ...serverFile,
              loading: false,
              deleting: false,
            })
          }
          this.$emit(
            'input',
            this.fileListWithPreview.map((f) => f.id).filter((f) => f !== void 0),
          )
        })
        .catch((error) => {
          console.error('Error uploading file:', error)
        })
    },
    removeFile(file) {
      file.deleting = true
      axios
        .delete(`${config.host}/file/${file.id}`)
        .then(() => {
          this.fileListWithPreview = this.fileListWithPreview.filter((f) => f.id !== file.id)
          this.$emit(
            'input',
            this.fileListWithPreview.map((f) => f.id).filter((f) => f !== void 0),
          )
        })
        .catch((error) => {
          console.error('Error deleting file:', error)
        })
        .finally(() => {
          file.deleting = false
        })
    },
    selectFile(file) {
      if (!this.enableSelection) return

      const fileIndex = this.selectedFiles.findIndex(f => f.id === file.id || f.tempId === file.tempId)

      if (fileIndex !== -1) {
        this.selectedFiles.splice(fileIndex, 1)
      } else {
        this.selectedFiles.push(file)
      }

      this.$emit('image-update', this.selectedFiles)
    },
    isSelected(file) {
      return this.selectedFiles.some(f => f.id === file.id || f.tempId === file.tempId)
    },
    isImage(file) {
      return file.fullPath.match(/\.(jpeg|jpg|gif|png)$/i)
    },
    getFileIcon(file) {
      const ext = file.name.split('.').pop().toLowerCase()
      switch (ext) {
        case 'pdf':
          return 'file-earmark-pdf'
        case 'doc':
        case 'docx':
          return 'file-earmark-word'
        case 'xls':
        case 'xlsx':
          return 'file-earmark-excel'
        case 'ppt':
        case 'pptx':
          return 'file-earmark-ppt'
        case 'zip':
        case 'rar':
          return 'file-earmark-zip'
        default:
          return 'file-earmark'
      }
    },
  },
}
</script>

<style>

.file-drop-area {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  margin-bottom: 10px;
}

.file-list {
  display: flex;
  flex-wrap: wrap;
}

.file-item {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.file-preview-wrapper {
  width: 100%;
  height: 150px;
  position: relative;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  overflow: hidden;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.file-preview-wrapper.selected {
  border-color: #3498db;
}

.preview, .file-icon, .grey-placeholder {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-icon {
  margin-top: 10px;
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.grey-placeholder {
  background-color: #e0e0e0;
}

.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.delete-button:hover {
  color: #ff5555;
}

</style>