<template>
  <div class="size-container">
    <modal-window
      :show-modal="showModal"
      @closeModal="closeModal"
    >
      <size-description-info
        :data-type="currentActiveTab"
        :data="selectedData"
        @closeModal="closeModal"
      />
    </modal-window>

    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1 search-label">Поиск</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Поиск"
            type="text"
            class="d-inline-block"
            @keyup="searchOnChange"
          />
        </div>
      </b-form-group>
    </div>
    <vue-good-table
      :columns="columns"
      :rows="rows"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-b-modal.modal-primary
                variant="outline-primary"
                @click="editData(props.row)"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Добавить описание</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <div
        slot="emptystate"
        class="empty-table-text"
      >
        {{ searchTerm.length !== 0 ? 'Результаты не найдены' : 'Для заполнения описания необходимо найти товар/категорию/размер через поиск' }}
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ModalWindow from '../../common/ModalWindow.vue'
import SizeDescriptionInfo from './SizeDescriptionInfo.vue'

export default {
  components: {
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    ModalWindow,
    SizeDescriptionInfo,
  },
  data() {
    return {
      debounceTimer: null,
      searchTerm: '',
      currentActiveTab: 0,
      rows: [],
      selectedData: {},
      columns: [
        {
          label: 'Тип',
          field: 'type',
        },
        {
          label: 'Название',
          field: 'name',
        },
        {
          label: 'Действие',
          field: 'action',
        },
      ],
      showModal: false,
    }
  },

  methods: {
    searchOnChange() {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(this.searchRequest, 1000)
    },
    async searchRequest() {
      const res = await this.$http.get(`product/search?filterText=${this.searchTerm}`)
        .then(r => r.data)

      this.rows = res.categories.map(category => ({
        type: 'Категория',
        name: category.title,
        dataType: 1,
        ...category,
      }))
        .concat(res.products.map(product => ({
          type: 'Товар',
          dataType: 0,
          ...product,
        })))
        .concat(res.optionValues.map(option => ({
          type: 'Размер',
          dataType: 2,
          ...option,
        })))
    },
    parseCategories(data) {
      data.forEach(category => {
        this.rows.push({ ...category, name: category.title })
        if (category.children.length !== 0) {
          this.parseCategories(category.children)
        }
      })
    },
    editData(data) {
      this.currentActiveTab = data.dataType
      this.selectedData = data
      this.showModal = true
    },
    closeModal(data) {
      this.showModal = data
    },
  },
}
</script>

<style lang="scss" scoped>
.search-label {
  font-size: 14px;
  font-weight: 500;
}
.empty-table-text {
  font-size: 14px;
  display: flex;
  justify-content: center;
  text-align: center;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
