<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Общая информация
        </h4>
      </div>
      <div class="card-body">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Заголовок вопроса"
              >
                <b-form-input
                  v-model="question.question"
                  placeholder="Вопрос"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>

    <div class="card">
      <div class="card-header" />
      <div class="card-body">
        <h4 class="card-title">
          Ответ на вопрос
        </h4>
        <div class="my-1">
          Ответ
        </div>
        <quill-editor
          v-model="question.answer"
        />
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Категория вопроса
        </h4>
      </div>
      <div class="card-body">
        <span class="bv-no-focus-ring col-form-label pt-0 label">
          Выберите категорию
        </span>
        <v-select
          v-model="categoriesOption"
          :close-on-select="true"
          :options="categoriesOptions"
          :reduce="categoriesOption => categoriesOption"
          :get-option-label="item => item.title"
          label="name"
          input-id="name"
          :value="categoriesOption"
          class="select"
          @search="(search, loading) => getQuestionsCategories(search, loading)"
        />
      </div>
    </div>

    <b-col>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="mr-1 save-button"
        :disabled="isloading"
        @click="saveItem"
      >
        <b-spinner
          v-if="isloading"
          small
        />
        {{ isloading ? 'Сохранение...' : 'Сохранить' }}
      </b-button>
    </b-col>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
  VBPopover,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import vSelect from 'vue-select'
import crudModule from '../../../libs/crud_module'

const questionModule = crudModule({
  entity_name: 'question',
})

const categoriesModule = crudModule({
  entity_name: 'question-category',
})

export default {
  name: 'QuestionPage',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BSpinner,
    vSelect,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      config: {
        allowInput: true,
        altFormat: 'd.m.Y H:i',
        altInput: true,
        enableTime: true,
        dateFormat: 'Z',
        locale: Russian, // locale for this instance only
      },
      isloading: false,
      question: {},
      categoriesOptions: [],
      categoriesOption: null,
      defaultCategoriesOptions: [],
      debounce: null,
    }
  },
  async created() {
    await categoriesModule.fetchItems()
      .then(result => {
        this.categoriesOptions = result.data.rows
        this.defaultCategoriesOptions = result.data.rows
      })
    if (this.$route.params.id === 'new') {
      return
    }
    try {
      await questionModule.fetchItem(this.$route.params.id, {
        query: {
          admin: '1',
        },
      }).then(result => { this.question = result.data })
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    saveItem() {
      if (this.$route.params.id === 'new') {
        this.create()
      } else {
        this.update()
      }
    },
    async update() {
      try {
        await questionModule.updateItem(this.question.id, this.question)
          .then(response => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Вопрос сохранен',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'questions' })
            return response
          })
          .catch(error => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      } catch (e) {
        this.isloading = false
        console.error(e)
      }
    },
    async create() {
      try {
        await questionModule.createItem({ ...this.question, categoryId: this.categoriesOption.id })
          .then(response => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Вопрос добавлен',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'questions' })
            return response
          })
          .catch(error => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      } catch (e) {
        this.isloading = false
        console.error(e)
      }
    },
    debounceSearch(search, loading, fn) {
      clearTimeout(this.debounce)
      if (search.length > 0) {
        this.debounce = setTimeout(fn, 600)
      } else {
        this.categoriesOptions = this.defaultCategoriesOptions
      }
    },
    getQuestionsCategories(search, loading) {
      this.debounceSearch(search, loading, async () => {
        try {
          await categoriesModule.fetchItems({ filterText: search })
            .then(result => {
              this.categoriesOptions = result.data.rows
              loading(false)
            })
        } catch (e) {
          console.error(e)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
