var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('filter-products',{on:{"trigerGetProducts":_vm.handlerTrigerGetProducts}}),_c('div',{staticClass:"card-body"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Поиск")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},on:{"input":_vm.searchProducts},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('b-button',{staticClass:"ml-1",attrs:{"title":"Синхронизировать все товары со страницы"},on:{"click":function($event){return _vm.$eventBus.$emit(_vm.EVENT.STORAGE.SYNC)}}},[_c('feather-icon',{attrs:{"icon":"RefreshCwIcon","size":"16"}})],1)],1)]),_c('div',{staticClass:"product-container"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [_c('div',{staticClass:"my-span",class:{
              bottom:
                _vm.$route.query &&
                _vm.$route.query.sorting &&
                _vm.$route.query.sorting.endsWith('asc') &&
                _vm.$route.query.sorting.startsWith(props.column.field),
              top:
                _vm.$route.query &&
                _vm.$route.query.sorting &&
                _vm.$route.query.sorting.endsWith('desc') &&
                _vm.$route.query.sorting.startsWith(props.column.field),
            },on:{"click":function($event){return _vm.handleColumnClick(props.column)}}},[_vm._v(" "+_vm._s(props.column.label)+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-primary",modifiers:{"modal-primary":true}}],attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.copyAssortmentId(props.row)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}}),_c('span',[_vm._v("Копировать ID связи")])],1),_c('b-dropdown-item',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-primary",modifiers:{"modal-primary":true}}],attrs:{"variant":"outline-primary"}},[_c('router-link',{attrs:{"to":{
                      name: 'product-info',
                      params: { id: props.row.id },
                    },"variant":"outline-primary"}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}}),_c('span',[_vm._v("Редактировать")])],1)],1),_c('b-dropdown-item',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-primary",modifiers:{"modal-primary":true}}],attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.acceptDelete(props.row.id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}}),_c('span',[_vm._v("Удалить")])],1)],1)],1)]):(props.column.field === 'quantity')?_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" "),_c('storage-sync',{attrs:{"product":props.row,"auto-fix-quantity-mode":_vm.autoFixQuantityMode}})],1):(props.column.field === 'isHidden')?_c('span',[_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"checked":"true","name":"check-view","switch":""},on:{"change":function($event){return _vm.switchItemVisibility(props.row.id, props.row.isHidden)}},model:{value:(props.row.isHidden),callback:function ($$v) {_vm.$set(props.row, "isHidden", $$v)},expression:"props.row.isHidden"}})],1):(props.column.field === 'price')?_c('span',[_vm._v(" "+_vm._s(_vm.formatPrice(props.row.price)))]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),_c('infinite-loading',{attrs:{"spinner":"bubbles","identifier":_vm.infiniteId},on:{"infinite":_vm.infiniteScroll}},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"})])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }