<template>
  <div class="card">
    <div class="card-body">
      <div class="custom-search d-flex">
        <div class="d-flex align-items-center">
          <label class="mr-1 search-title">Поиск</label>
          <b-form-input
            v-model="searchData"
            placeholder="Поиск"
            type="text"
            class="d-inline-block"
            @update="search => searchTransactions(search)"
          />
        </div>
        <div class="search-item">
          <label for="select-type">Тип транзакции</label>
          <v-select
            id="select-type"
            v-model="type"
            :close-on-select="true"
            :options="typeOptions"
            :value="type"
            class="select"
            multiple
            @input="search => searchTransactions(search)"
          />
        </div>
        <div class="datepickers-container search-item">
          <div>
            <label
              class="font-size"
              for="datepicker-1"
            >Дата начала</label>
            <b-form-datepicker
              id="datepicker-1"
              v-model="dateFrom"
              placeholder="YYYY-MM-DD"
              locale="ru"
              @input="search => searchTransactions(search)"
            />
          </div>
          <div>
            <label
              class="font-size"
              for="datepicker-2"
            >Дата окончания</label>
            <b-form-datepicker
              id="datepicker-2"
              v-model="dateTo"
              placeholder="YYYY-MM-DD"
              locale="ru"
              @input="search => searchTransactions(search)"
            />
          </div>
        </div>
        <div class="search-item">
          <label for="select-type">Статус транзакции</label>
          <v-select
            id="select-type"
            v-model="status"
            :close-on-select="true"
            :options="statusOptions"
            :value="status"
            class="select"
            multiple
            @input="search => searchTransactions(search)"
          />
        </div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="outline-primary"
          @click="cleanFilters"
        >
          Очистить фильтры
        </b-button>
      </div>

      <vue-good-table
        :columns="columns"
        :rows="rows"
        :sort-options="{
          enabled: false,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'wallet.walletOwner'">
            {{ props.row.wallet.walletOwner.name }} {{ props.row.wallet.walletOwner.surname }}
          </span>

          <span v-else-if="props.column.field === 'transactionInitiator'">
            {{ props.row.transactionInitiator.name }} {{ props.row.transactionInitiator.surname }}
          </span>

          <span v-else-if="props.column.field === 'transactionAmount'">
            {{ `₽ ${props.row.transactionAmount * (props.row.isNegative ? -1 : 1) / 100}` }}
          </span>

          <span v-else-if="props.column.field === 'createdAt'">
            {{ $moment(props.row.createdAt).format('DD.MM.YYYY hh:mm') }}
          </span>

          <span v-else-if="props.column.field === 'error'">
            {{ props.row.error ? props.row.error : '-' }}
          </span>

          <span v-else-if="props.column.field === 'comment'">
            {{ props.row.comment ? props.row.comment : '-' }}
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
      <infinite-loading
        spinner="bubbles"
        @infinite="infiniteScroll"
      >
        <div slot="no-more" />
        <div slot="no-results" />
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import {
  BFormInput,
  BButton,
  BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import InfiniteLoading from 'vue-infinite-loading'
import crudModule from '../../libs/crud_module'

const transactionsModule = crudModule({
  entity_name: 'virtual-payment/transaction',
})

export default {
  components: {
    VueGoodTable,
    BFormInput,
    BButton,
    BFormDatepicker,
    InfiniteLoading,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      skipCount: 0,
      maxResultCount: 10,
      type: [],
      status: [],
      dateFrom: '',
      dateTo: '',
      searchData: '',
      scrollState: undefined,
      columns: [
        {
          label: 'ID Кошелька',
          field: 'wallet.id',
        },
        {
          label: 'Владелец кошелька',
          field: 'wallet.walletOwner',
        },
        {
          label: 'Инициатор транзакции',
          field: 'transactionInitiator',
        },
        {
          label: 'Тип',
          field: 'type',
        },
        {
          label: 'Сумма транзакции',
          field: 'transactionAmount',
        },
        {
          label: 'Дата',
          field: 'createdAt',
        },
        {
          label: 'Статус',
          field: 'status',
        },
        {
          label: 'Ошибка',
          field: 'error',
        },
        {
          label: 'Комментарий',
          field: 'comment',
        },
      ],
      typeOptions: ['Покупка', 'Платёж', 'Сервисное движение средств'],
      statusOptions: ['Успешно', 'Ошибка', 'Ожидает подтверждения'],
      rows: [],
    }
  },
  mounted() {
    if (this.$route.query) {
      this.searchData = this.$route.query.filterText
    }
  },
  methods: {
    async infiniteScroll($state) {
      this.scrollState = $state
      const payload = {
        type: this.type,
        status: this.status,
        filterText: this.searchData,
        skipCount: this.skipCount,
        maxResultCount: this.maxResultCount,
      }

      if (this.dateFrom) {
        payload.dateFrom = this.dateFrom
      }

      if (this.dateTo) {
        payload.dateTo = this.dateTo
      }
      await transactionsModule.fetchItems(payload)
        .then(res => {
          if (res.data.rows.length > 0) {
            this.rows.push(...res.data.rows)
            this.skipCount += this.maxResultCount
            $state.loaded()
          } else {
            $state.complete()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    debounceSearch(search, fn) {
      clearTimeout(this.debounce)
      if (search && search.length >= 0) {
        this.debounce = setTimeout(fn, 600)
      }
    },
    async searchTransactions(search) {
      const payload = {
        type: this.type,
        status: this.status,
        filterText: this.searchData,
        skipCount: 0,
        maxResultCount: this.maxResultCount,
      }
      if (this.dateFrom) {
        payload.dateFrom = this.dateFrom
      }

      if (this.dateTo) {
        payload.dateTo = this.dateTo
      }
      this.debounceSearch(search, async () => {
        try {
          await transactionsModule.fetchItems(payload)
            .then(result => {
              this.rows = result.data.rows
              if (this.scrollState) {
                this.scrollState.reset()
              }
            })
        } catch (e) {
          console.error(e)
        }
      })
    },
    cleanFilters() {
      this.type = []
      this.status = []
      this.dateFrom = ''
      this.dateTo = ''
      this.searchData = ''
      this.searchTransactions([1])
    },
  },
}
</script>

<style lang='scss' scoped>
.datepickers-container {
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 5px;
}
.header-button-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.notification-container {
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    width: 16px;
    height: 16px;
  }

  p {
    max-width: 410px;
    margin-bottom: 0;
  }
}
.search-title {
  margin-bottom: 0;
  font-size: 1rem;
}
.custom-search {
    width: 100%;
    align-items: end;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    gap: 15px;

    .search-item {
      min-width: 170px;
    }

    .form-group {
      margin-bottom: 0;
    }
}
</style>

<style lang='scss'>
.edit-button {
  padding: 0.65rem 1.28rem;
  display: flex;
  align-items: center;

  &:hover {
    background-color: rgba(115, 103, 240, 0.12);
  }
}

.search-item {
  .vs__dropdown-toggle {
    border: 1px solid #d8d6de;
  }

  .vs__open-indicator {
    path {
      fill: #d8d6de;
    }
  }
}

.dark-layout {
  .search-item {
    .vs__open-indicator {
      path {
        fill: #404656;
      }
    }
  }
}

@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
